<script>
import { computed } from "vue";
import Markdown from "@/components/forms/Markdown.vue";

export default {
    name: "DocumentChunk",
    components: {
        Markdown,
    },
    props: {
        content: {
            type: String,
            default: "no content",
        },
    },
    setup(props) {
        const content = computed(() => props.content ?? "no content");
        return {
            content,
        };
    },
};
</script>

<template>
    <div class="DocumentChunk">
        <div class="chunk">
            <Markdown :model-value="content"></Markdown>
        </div>
    </div>
</template>

<style scoped>
.chunk {
    background-color: rgba(0, 0, 0, 0.85);
    color: #fff;
    padding: 8px 12px;
    border-radius: 4px;
    min-width: 250px;
    max-width: 250px;
    font-size: 14px;
    z-index: 1000;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
}

.chunk::after {
    content: "";
    position: absolute;
    top: -6px;
    left: 10px;
    border-width: 6px;
    border-style: solid;
    border-color: transparent transparent rgba(0, 0, 0, 0.85) transparent;
}
</style>
