import {getApiUrl} from "@/store/lib/apiUrls.js";

export const getDataTypeActionUrl = ({ // see actionRouter
    _appId,
    _dataType,
    endpoint,
}) => {
    if (!_appId) throw new Error('_appId is required.')
    if (!_dataType) throw new Error('_dataType is required.')
    if (!endpoint) throw new Error('endpoint is required.')

    let url = `${getApiUrl()}/actions/${_appId}/${_dataType}/action/${endpoint}`
    return url
}
