<script>
import { computed, ref, watch } from 'vue'

import MarkdownIt from 'markdown-it'
import MarkdownItAbbr from 'markdown-it-abbr'
import MarkdownItAnchor from 'markdown-it-anchor'
import MarkdownItDeflist from 'markdown-it-deflist'
import MarkdownItFootnote from 'markdown-it-footnote'
import MarkdownItHighlightjs from 'markdown-it-highlightjs'
import MarkdownItIns from 'markdown-it-ins'
import MarkdownItMark from 'markdown-it-mark'
import MarkdownItSub from 'markdown-it-sub'
import MarkdownItSup from 'markdown-it-sup'
import MarkdownItTasklists from 'markdown-it-task-lists'
import MarkdownItTOC from 'markdown-it-toc-done-right'
import FileReference from '../../chi-assistants/components/Assistant/Markdown/FileReference.vue'

export default {
    name: 'MarkdownIt',
    emits: ['click'],
    components: {
        FileReference,
    },
    props: {
        anchor: {
            type: Object,
            default: () => ({}),
        },
        breaks: {
            type: Boolean,
            default: false,
        },
        html: {
            type: Boolean,
            default: false,
        },
        highlight: {
            type: Object,
            default: () => ({}),
        },
        langPrefix: {
            type: String,
            default: 'language-',
        },
        linkify: {
            type: Boolean,
            default: false,
        },
        plugins: {
            type: Array,
            default: () => [],
        },
        quotes: {
            type: String,
            default: '“”‘’',
        },
        source: {
            type: String,
            default: '',
        },
        tasklists: {
            type: Object,
            default: () => ({}),
        },
        toc: {
            type: Object,
            default: () => ({}),
        },
        typographer: {
            type: Boolean,
            default: false,
        },
        xhtmlOut: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        const mdEl = ref(null)
        const md = ref()
        const source = computed(() => props.source || '')

        const renderMarkdown = (v) => {
            let markdown = new MarkdownIt()
                .use(MarkdownItAbbr)
                .use(MarkdownItAnchor, props.anchor)
                .use(MarkdownItDeflist)
                .use(MarkdownItFootnote)
                .use(MarkdownItHighlightjs, props.highlight)
                .use(MarkdownItIns)
                .use(MarkdownItMark)
                .use(MarkdownItSub)
                .use(MarkdownItSup)
                .use(MarkdownItTasklists, props.tasklists)
                .use(MarkdownItTOC, props.toc)
                .set({
                    breaks: props.breaks,
                    html: true,
                    langPrefix: props.langPrefix,
                    linkify: props.linkify,
                    quotes: props.quotes,
                    typographer: props.typographer,
                    xhtmlOut: props.xhtmlOut,
                })

            props.plugins.forEach(({ plugin, options = {} }) => {
                markdown.use(plugin, options)
            })

            md.value = markdown.render(v)
        }

        watch(
            source,
            (v) => {
                renderMarkdown(v)
            },
            { immediate: true }
        )

        return {
            mdEl,
            md,
        }
    },
}
</script>

<template>
    <div
        ref="mdEl"
        class="markdownIt"
        v-html="md"
        v-bind="$attrs"
        @click="$emit('click')"
    ></div>
</template>
