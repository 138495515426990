<script>
import {computed} from "vue";
import {state} from "@/store/store";
import Gravatar from "@/components/gravatar/Gravatar.vue";
import {useDocument} from '@/composables/document/useDocument.js'

export default {
  name: "PersonDetailsCard",
  components: {
    Gravatar,
  },
  props: {
    _id: {
      type: String,
      required: true,
    },
    isApplicant: {
      default: false,
    },
  },
  setup(props) {
    const {document} = useDocument({
      alias: '_Person',
      filter: {
        _dataType: '_Person',
        _id: props._id,
      },
      options: {

      }
    })

    const name = computed(() =>
        document.value.name ||
        (document.value.firstName && document.value.firstName && document.value.firstName + ' ' + document.value.lastName)
    )

    const awards = computed(() => {
      return false
    })


    return {
      awards,
      document,
      name,
      state,
    }
  }
}
</script>

<template>
  <div class="personDetailsCard bg-white p-8 rounded-xl">
    <div class="flex items-center gap-8">
      <div class="rounded-full bg-gray-300 overflow-clip flex items-center">
        <Gravatar
            v-if="document.email"
            class=""
            :email="document.email"
            :size="96"
        ></Gravatar>
      </div>
      <div class="font-bold text-3xl tracking-tight mb-3">
        {{ document.title }} {{ name }}
      </div>
    </div>
    <div class="font-thin text-2xl">
      {{ document.programme }}
    </div>
      <section v-if="!document.programme" class="mt-2">
          Not currently enrolled in any programs ...
      </section>
    <p class="mb-4">
      {{ document.university }}
    </p>
    <section v-if="awards">
      <div class="text-xl font-semibold tracking-tight mb-3">
        Awards
        ...
      </div>
    </section>
    <section v-else>
      <div class="">
        No awards yet
        ...
      </div>
    </section>


  </div>
</template>

<style scoped>

</style>