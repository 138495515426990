import {throttleBufferedAction} from '@/store/lib/throttledBufferedAction.js';
import axios from '@/plugins/axios.js';
import {getAssistantUploadUrl} from '@/store/lib/apiUrls.js';
import {LIST_UPLOADS, UPLOAD} from '@/store/operations.js';
import {handleResponse} from '@/store/actions/lib/handleResponse.js';

export default {
    [LIST_UPLOADS](context, {_appId, _id}) {
        return throttleBufferedAction({
            action: axios.get,
            url: getAssistantUploadUrl({_appId, _id}),
        })
            .then(handleResponse(context, LIST_UPLOADS))
    },
    [UPLOAD](context, {
        _appId,
        _id,
        formData,
        onProgress
    }) {
        return axios.post(
            getAssistantUploadUrl({_appId, _id}),
            formData,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
                onProgress,
            })
            .then(handleResponse(context, UPLOAD))
    },
}