<script>
import TextInput from "@/components/forms/TextInput.vue";
import Button from "@/components/forms/Button.vue";
import LSection from "@/components/layout/LSection.vue";
import {computed, onMounted, reactive, ref, watchPostEffect} from "vue";
import {isStringWithLength} from '@mehimself/cctypehelpers';
import {isEmailAddress} from '@mehimself/cctypehelpers';
import {LOGIN} from "@/store/operations.js";
import {usePassphrase} from "@/lib/usePassphrase";
import {useStore} from "vuex";
import StatusMessage from "@/components/status/StatusMessage.vue";
import {useRoute, useRouter} from "vue-router";
import {useStatusMessage} from "@/composables/useStatusMessage";
import {repopulateObject} from '@mehimself/cctypehelpers';
import {normalizeRedirect} from "@/lib/normalizeRedirect";
import PrimaryButton from "../../../../chi-assistants/components/Button/PrimaryButton.vue";
import SecondaryButton from "../../../../chi-assistants/components/Button/SecondaryButton.vue";

export default {
  name: "Login",
  components: {
    SecondaryButton,
    PrimaryButton,
    Button,
    LSection,
    StatusMessage,
    TextInput,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    const {setMessage, deleteMessage} = useStatusMessage(LOGIN);

    const login = reactive({
      email: "",
      password: "",
    });

    const isSent = ref(false);
    const inputError = reactive({
      email: "",
      password: "",
    });
    const {hashPassphrase} = usePassphrase();

    const isValid = computed(
        () =>
            !isSent.value &&
            Object.values(inputError)
                .every((msg) => msg === "")
    );

    onMounted(() => {
      const redirectTo = route.query.redirectTo;
      if (redirectTo) {
        const normalizedRedirectTo = normalizeRedirect(redirectTo);
        const url = new URL(window.location.protocol + '//' + normalizedRedirectTo);
        window.location.href = window.location.protocol + '://' + url.toString() + '?modal=login';
      }
    });
    deleteMessage();

    const onEmailChange = (value) => {
      login.email = value.trim()
      isSent.value = false
    }
    const onPasswordChange = (value) => {
      login.password = hashPassphrase(value.trim())
      isSent.value = false
    }

    const onForgotPassword = () => router.push({
      query: {
        modal: 'forgot',
        redirect: router.currentRoute.value.query?.redirect
      }
    })

    watchPostEffect(() => {
      const {email, password} = login;

      inputError.email =
          email !== null && !isEmailAddress(email)
              ? "A valid email address is required"
              : "";
      inputError.password =
          password !== null && !isStringWithLength(password)
              ? "A password is required"
              : "";
    });

    const onSubmit = () => {
      repopulateObject(inputError, {})
      deleteMessage();
      inputError.email = isEmailAddress(login.email) ? "" : "A valid email address is required";
      inputError.password = isStringWithLength(login.password) ? "" : "A password is required";
      if (!isValid.value) return

      isSent.value = true;
      store
          .dispatch(LOGIN, login)
          .then(({data}) => {
            const {Error, User} = data;
            isSent.value = false;
            if (Error) {
              setMessage({
                isError: true,
                body: Error.message,
              });
            }
            router.push({query: {}})
          })
          .finally(() => {
            isSent.value = false;
          })

      Object.keys(login).forEach((key) => delete login[key]);
    };
    const onCancel = () => router.push('/')

    return {
      isValid,
      login,
      inputError,
      onEmailChange,
      onForgotPassword,
      onPasswordChange,
      onCancel,
      onSubmit,
    };
  },
};
</script>

<template>
  <div class="login">
    <LSection :noMargin="true">
      <form @submit.prevent="onSubmit" ref="form" class="form">
        <h1 class="heading1">
          Login
          <hr class="gradientLine"/>
        </h1>
        <div class="flex flex-wrap">
          <div class="w-full mb-6">
            <label for="email">Email</label>
            <TextInput
                :tabIndex="1"
                id="email"
                name="email"
                placeholder="Email address"
                required
                @change="onEmailChange"
                @input="onEmailChange"
            ></TextInput>
          </div>
          <div class="w-full mb-6">
            <label for="pass">Password</label>
            <TextInput
                :tabIndex="2"
                id="pass"
                name="pass"
                type="password"
                placeholder="Password"
                required
                @change="onPasswordChange"
                @input="onPasswordChange"
                @submit="onSubmit"
            ></TextInput>
            <div class="w-full flex justify-end">
              <a
                  class="text-gray-500 text-sm underline hover:text-gray-600 cursor-pointer"
                  @click="onForgotPassword"
              >
                Forgot password
              </a>
            </div>
          </div>
        </div>
        <div class="flex w-full justify-end">
          <StatusMessage operation="LOGIN"></StatusMessage>
          <div class="w-full flex gap-2 justify-end">
            <SecondaryButton button-text="Back" @click="onCancel"/>

            <!--            <Button-->
            <!--                class="button secondary"-->
            <!--                :tabindex="3"-->
            <!--                type="submit"-->
            <!--                @click="onCancel"-->
            <!--            >-->
            <!--              <template #right>Back</template>-->
            <!--            </Button>-->
            <PrimaryButton button-text="Login" :disabled="!isValid" @click="onSubmit"/>
            <!--            <Button-->
            <!--                :is-disabled="!isValid"-->
            <!--                :is-primary="isValid"-->
            <!--                @click="onSubmit"-->
            <!--            >-->
            <!--              Login-->
            <!--            </Button>-->
          </div>
        </div>
      </form>
    </LSection>
  </div>
</template>

<style scoped></style>
