// sync with file server/router/documents/lib/expandMatchFilterExpression.js
import { isRef } from 'vue'
import { isArray, isObject } from '@mehimself/cctypehelpers'
import { ingestFilterRef } from '@/composables/document/lib/ingestFilterRef.js'

const ingestSorting = (sorting) => {
    // todo: support multiple sort attributes
    sorting = sorting || []
    const [attributeName, direction] = (sorting[0] ?? '')
        .split(',')
        .map((e) => e.trim())
    if (!attributeName) return {}
    return { attributeName, direction }
}

const sort_ascending = (name, direction) => (a, b) => {
    const isGreater =
        a[name] !== undefined &&
        a[name] !== null &&
        b[name] !== undefined &&
        b[name] !== null &&
        a[name] > b[name]
    return isGreater && direction === 'asc' ? 1 : -1
}

const applyFilter = (docs, filter) => {
    /*
    filter:
        multi-attribute filter syntax:
        {
          type: "AND", // Logical operator
          filters: [
            {
              field: "age",
              operator: "greater_than",
              value: 30
            },
            {
              type: "OR",
              filters: [
                {
                  field: "name",
                  operator: "contains",
                  value: "John"
                },
                {
                  field: "skills",
                  operator: "in",
                  value: ["Python", "JavaScript"]
                }
              ]
            }
          ]
        }

        alternate simple filter syntax:
        {
          field: "age",
          operator: "greater_than",
          value: 30
        }
 */
    docs = docs ?? []
    const hasNoFilter =
        !filter || !isObject(filter) || !Object.keys(filter).length

    if (hasNoFilter) return docs
    if (filter.type) {
        // multi-attribute filter
        const { type, filters } = filter
        if (type === 'AND') {
            docs = docs.filter((doc) =>
                filters.every((f) => applyFilter([doc], f).length > 0)
            )
        } else if (type === 'OR') {
            docs = docs.filter((doc) =>
                filters.some((f) => applyFilter([doc], f).length > 0)
            )
        }
    } else {
        // sync with @mehimself/ccneo4jdatabase/part_expandMatchFilterExpression.js
        const { field, operator, value } = filter
        docs = docs.filter((doc) => {
            const docValue = doc[field]
            switch (operator) {
                case '=':
                    return docValue === value
                case '!=':
                    return docValue !== value
                case '>':
                    return docValue > value
                case '>=':
                    return docValue >= value
                case '<':
                    return docValue < value
                case '<=':
                    return docValue <= value
                case 'contains':
                    return docValue.includes(value)
                case '!contains':
                    return !docValue.includes(value)
                case 'in':
                    return value.includes(docValue)
                case '!in':
                    return !value.includes(docValue)
                case 'startsWith':
                    return docValue.startsWith(value)
                case '!startsWith':
                    return !docValue.startsWith(value)
                case 'endsWith':
                    return docValue.endsWith(value)
                case '!endsWith':
                    return !docValue.endsWith(value)
                default:
                    return false
            }
        })
    }
    return docs
}
export const filterDocuments = ({
    _appId,
    _dataType,
    documents,
    repo,
    filter,
    sorting,
}) => {
    if (!isArray(repo)) {
        console.log(882, repo)
        throw new Error('repo must be an array')
    }
    sorting = isRef(sorting) ? sorting.value : sorting
    filter = ingestFilterRef({
        _appId,
        _dataType,
        filter,
    })

    // apply filter to docs
    let docs = applyFilter(repo, filter.value)

    // sort docs
    const { attributeName, direction } = ingestSorting(sorting)
    if (attributeName) {
        docs.sort(sort_ascending(attributeName, direction))
    }
    documents.value = docs
}
