import {ActionError} from '@/lib/ActionError.js';
import {MissingRequiredParameterError} from '@/lib/MissingRequiredParameterError.js';
import {isString} from '@mehimself/cctypehelpers';

const hydrateErrorResponse = response => {
    if (response.status === 401) response.Error = new InsufficientPrivilegesError()
    else if (response.status === 403) response.Error = new InsufficientPrivilegesError()
    else if (response.status === 404) response.Error = new ActionError(`Service action not found.`)
    else if (response.status === 500) response.Error = new ActionError(`Server error.`)
    else if (response.status === 503) response.Error = new ActionError(`The server is currently unavailable.`)
    // todo: catch undefined dataType on GET/POST jsonAsset list response
    return response
}
const handleAuthErrorResponse = response => {
    hydrateErrorResponse(response)
    return response
}

const handleActionErrorResponse = response => { // todo: smells awkward: throwing server errors as client errors
    const err = response.data.Error
    if (err && err.name?.endsWith('MissingRequiredParamError')) throw new MissingRequiredParameterError('action', err.attributeName)
    return response
}

const normalizeError = response => {
    let err = response.data?.error || response.data?.Error
    if (err) {
        if (isString(err)) err = {
            name: 'Request Error',
            message: err
        }
        err = {...err}
        response.data.Error = err
        delete response.data.error
    }
    return response
}
export const configureResponses = axios =>
    axios.interceptors.response.use(handleAuthErrorResponse, error => {
        if (!error.response) throw error
        let response = error.response
        normalizeError(response)
        handleAuthErrorResponse(response)
        handleActionErrorResponse(response)
        return response
    })

class InsufficientPrivilegesError extends ActionError {
    constructor() {
        super(`This action is not allowed.`)
        this.name = 'Insufficient Privileges'
    }
}
