<script>
import { computed, onMounted, ref, watch } from "vue";
import IconThumbUp from "@/components/icons/ThumbUp.vue";
import IconComment from "@/components/icons/Comment.vue";
import IconShare from "@/components/icons/Share.vue";
import Markdown from "@/components/forms/Markdown.vue";
import { useStore } from "vuex";
import { LOG_CREA_VISIONS_ENTRY } from "../operations.js";
import { useWindowDimensions } from "@/lib/useWindowDimensions";
import Creation from "@/components/icons/Creation.vue";

export default {
  name: "ImageSlider",
  components: {
    Creation,
    Markdown,
    IconComment,
    IconShare,
    IconThumbUp,
  },
  props: {
    sourceUrl: String,
    imageUrl: String,
    title: String,
    Description: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const store = useStore();
    const image = ref(null);
    const hasLiked = ref(false);
    const hasShared = ref(false);
    const sliderPosition = ref(800);
    const imageWidth = ref(800);
    const { aspectType } = useWindowDimensions();

    const styleAfter = computed(() => ({
      clipPath: `inset(0 0 0 ${sliderPosition.value}px)`,
    }));
    const style = computed(() => ({
      left: `${sliderPosition.value}px`,
    }));

    watch(
      () => props.sourceUrl,
      () => {
        resetPosition();
      }
    );

    onMounted(() => {
      const img = new Image();
      img.src = props.sourceUrl;
      img.onload = () => {
        imageWidth.value = image.value?.clientWidth;
        sliderPosition.value = image.value?.clientWidth / 2;
        resetPosition();
      };
    });

    const resetPosition = () => {
      sliderPosition.value = imageWidth.value / 2;
    };
    const onLikeImage = () => {
      if (hasLiked.value) return;
      hasLiked.value = true;

      store.dispatch(LOG_CREA_VISIONS_ENTRY, {
        action: "like Image",
        imageJSON: JSON.stringify(props),
      });
    };
    const onShareImage = () => {
      if (hasShared.value) return;
      hasShared.value = true;
      // show selection of social media

      store.dispatch(LOG_CREA_VISIONS_ENTRY, {
        action: "share Image",
        imageJSON: JSON.stringify(props),
      });
    };

    return {
      aspectType,
      hasLiked,
      hasShared,
      image,
      imageWidth,
      sliderPosition,
      style,
      styleAfter,

      resetPosition,
      onLikeImage,
      onShareImage,
    };
  },
};
</script>

<template>
  <div class="imageSlider flex flex-col gap-4">
    <div class="relative flex-col flex h-full">
      <img
        :src="sourceUrl"
        alt="Before Image"
        class="w-full h-full object-contain cursor top-0 shrink-0 cursor-pointer"
      />
      <img
        ref="image"
        :src="imageUrl"
        :style="styleAfter"
        alt="After Image"
        class="absolute top-0 left-0 w-full h-full object-contain cursor-pointer"
      />
      <div
        :style="style"
        class="absolute h-full top-0 bottom-0 cursor-pointer"
      ></div>
      <input
        v-model="sliderPosition"
        :max="imageWidth"
        class="absolute top-1/2 transform -translate-y-1/2 w-full slider cursor-pointer"
        min="0"
        type="range"
      />
    </div>

    <div>
      <!--      <div class="mt-4 flex items-center p-6 gap-10">-->
      <!--      <div :class="{'text-blue-500': hasShared}" title="I would share this image on social media">-->
      <!--        <IconShare-->
      <!--            :size="48"-->
      <!--            @click="onShareImage"-->
      <!--        ></IconShare>-->
      <!--      </div>-->
      <!--      </div>-->
      <div class="flex flex-col items-start text-xl">
        <label>Title</label>
        <div class="flex w-full justify-between items-center mb-6">
          <div class="text-3xl font-bold">{{ title }}</div>
          <div
            :class="{ 'text-blue-500': hasLiked }"
            class="cursor-pointer hover:opacity-70"
            title="like this image"
          >
            <IconThumbUp :size="48" @click="onLikeImage"></IconThumbUp>
          </div>
        </div>
        <div class="flex items-center gap-2 mb-2">
          <Creation></Creation>
          <label>AI image description</label>
        </div>
        <Markdown :modelValue="Description"></Markdown>
        <!--      <h4 class="pt-5 font-semibold">Comments</h4>-->
      </div>
    </div>
  </div>
</template>

<style scoped>
.slider {
  appearance: none;
  height: 100%;
  background: transparent;
  outline: none;
  opacity: 0.7;
  transition: opacity 0.2s;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  appearance: none;
  width: 80px;
  height: 80px;
  background: white url("../assets/swipe.svg") no-repeat center center;
  background-size: 80%;
  cursor: pointer;
  border-radius: 50%;
}

.slider::-moz-range-thumb {
  width: 80px;
  height: 80px;
  background: white url("../assets/swipe.svg") no-repeat center center;
  background-size: 80%;
  cursor: pointer;
  border-radius: 50%;
}
</style>
