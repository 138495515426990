// ACCOUNT OPERATIONS
export const LIST_USERS = 'LIST_USERS'
export const LIST_ASSET_MEMBERS = 'LIST_ASSET_USERS'
export const GET_USER = 'GET_USER'
export const REQUEST_ACCOUNT = 'REQUEST_ACCOUNT'
export const LOGIN = 'LOGIN'
export const LOGOUT = 'LOGOUT'
export const RESUME_SESSION = 'RESUME_SESSION'
export const REQUEST_FORGET_USER = 'REQUEST_FORGET_USER'

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD'
export const REQUEST_PASSWORD_RESET = 'REQUEST_PASSWORD_RESET'
export const REQUEST_CHANGE_EMAIL = 'REQUEST_CHANGE_EMAIL'

export const VERIFY_ACTIVATE_ACCOUNT_TOKEN = 'VERIFY_ACTIVATE_ACCOUNT_TOKEN'
export const VERIFY_RESET_PASSWORD_TOKEN = 'VERIFY_RESET_PASSWORD_TOKEN'
export const ACTIVATE_ACCOUNT = 'ACTIVATE_ACCOUNT'
export const RESET_PASSWORD = 'RESET_PASSWORD'
export const CONFIRM_CHANGE_EMAIL = 'CONFIRM_CHANGE_EMAIL'
export const ACTIVATE_NEW_EMAIL = 'ACTIVATE_NEW_EMAIL'
export const CONFIRM_DOWNLOAD_MY_DATA = 'CONFIRM_DOWNLOAD_MY_DATA'
export const CONFIRM_FORGET_ABOUT_ME = 'CONFIRM_FORGET_ABOUT_ME'
