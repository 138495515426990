<script>
import LView from "@/components/layout/LView.vue";
import LSection from "@/components/layout/LSection.vue";

export default {
  name: "CHIAPIHome",
  components: {
    LSection,
    LView,
  }
}
</script>

<template>
  <div class="CHIAPI home">
    <LView class="flex flex-col">
      <LSection class="bg-black text-white py-20">
        <div
            class="flex k1:flex-row k1:items-center flex-col w-full h-full k1:px-12 py-12 justify-between k1:gap-4"
        >
          <div class="w-2/3">
            <h1 class="text-6xl font-bold">
              API pages of the Center of Hybrid Intelligence
            </h1>
            <p class="text-2xl mt-6 font-normal text-gray-300">
              CHI API
            </p>
          </div>

        </div>
      </LSection>
      <LSection
          class="flex-1"
          :useMargin="false"
      >
        <div class="flex flex-col h-full">
          <div class="image w-full bg-cover bg-center bg-no-repeat">
          </div>
        </div>
      </LSection>
    </LView>
  </div>
</template>

<style scoped>
.image {
  background-image: url('../../../assets/placeholders/playgroundai/meadow.png');
  flex: 1
}
</style>