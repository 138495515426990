import { createApp } from 'vue'
import App from './App.vue'
import router from './router/router.js'
import store from './store/store.js'
import MarkdownIt from '@/components/MarkdownIt.vue'
import './assets/css/tailwind.css'

// style defaults
import './index.css'

// highlight.js theme
import './hljs_github-dark.css'

// custom dayjs
import dayjs from './plugins/dayjs.js'

// custom axios
import axios from './plugins/axios.js'

// toastify
import 'vue3-toastify'
import 'vue3-toastify/dist/index.css'

//i18n for multiple language
import i18n from "@/plugins/i18n.js";

createApp(App).
    use(i18n).
    use(store).
    use(router).
    provide('AXIOS', axios).
    provide('DAYJS', dayjs).
    component('MarkdownIt', MarkdownIt).
    mount('#app')

