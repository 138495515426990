export default [
    {
        path: '/lobby',
        name: 'lobby',
        component: () => import('./components/views/Lobby.vue')
    },
    {
        path: '/game',
        name: 'game',
        component: () => import('./components/views/Game.vue')
    },
]