import { createI18n } from 'vue-i18n'
import pluralRules from '../../chi-delphi/locales/pluralization.js'

// Import translations for each language and game
import enQuizTime from '../../chi-delphi/locales/en/quizTime.json'
import dkQuizTime from '../../chi-delphi/locales/dk/quizTime.json'
import enIntrodution from '../../chi-delphi/locales/en/introdution.json'
import dkIntrodution from '../../chi-delphi/locales/dk/introdution.json'
import enFlashMatch from '../../chi-delphi/locales/en/flashMatch.json'
import dkFlashMatch from '../../chi-delphi/locales/dk/flashMatch.json'
import enWordLinks from '../../chi-delphi/locales/en/wordLinks.json'
import dkWordLinks from '../../chi-delphi/locales/dk/wordLinks.json'
import enShared from '../../chi-delphi/locales/en/shared.json'
import dkShared from '../../chi-delphi/locales/dk/shared.json'
import enColorCatch from '../../chi-delphi/locales/en/colorCatch.json'
import dkColorCatch from '../../chi-delphi/locales/dk/colorCatch.json'
import enMimeMix from '../../chi-delphi/locales/en/mimeMix.json'
import dkMimeMix from '../../chi-delphi/locales/dk/mimeMix.json'
import enMorningMemo from '../../chi-delphi/locales/en/morningMemo.json'
import dkMorningMemo from '../../chi-delphi/locales/dk/morningMemo.json'
import { useLocalStorage } from '@/composables/useLocalStorage.js'
const messages = {
    en: {
        shared: enShared,
        introduction: enIntrodution,
        quizTime: enQuizTime,
        flashMatch: enFlashMatch,
        wordLinks: enWordLinks,
        colorCatch: enColorCatch,
        mimeMix: enMimeMix,
        morningMemo: enMorningMemo,
    },
    dk: {
        shared: dkShared,
        quizTime: dkQuizTime,
        introduction: dkIntrodution,
        flashMatch: dkFlashMatch,
        wordLinks: dkWordLinks,
        colorCatch: dkColorCatch,
        mimeMix: dkMimeMix,
        morningMemo: dkMorningMemo,
    },
}
const languageStorage = useLocalStorage('language', 'en') // 'en' as a default language
const savedLanguage = languageStorage.getLocalStorageValue() || 'en'
const i18n = createI18n({
    locale: savedLanguage,
    fallbackLocale: 'en',
    globalInjection: true,
    legacy: false,
    messages,
    pluralRules,
})

export default i18n
