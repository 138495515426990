export const INIT_STUDY_SUITE = 'DELPHI_INIT_STUDY_SUITE'
export const INIT_LOCAL_SUITE = 'DELPHI_INIT_LOCAL_SUITE'
export const START_GAME = 'DELPHI_START_GAME'
export const START_GAME_ROUND = 'DELPHI_START_GAME_ROUND'
export const END_GAME_ROUND = 'DELPHI_END_GAME_ROUND'
export const LOG_USER_PROMPT = 'DELPHI_LOG_USER_PROMPT'
export const LOG_USER_RESPONSE = 'DELPHI_LOG_USER_RESPONSE'
export const END_GAME = 'DELPHI_END_GAME'
export const GENERATE_DATA_FILES = 'DELPHI_GENERATE_DATA_FILES'
export const LIST_DATA_FILES = 'DELPHI_LIST_DATA_FILES'
export const REQUEST_GLOVE_DISTANCE = 'DELPHI_REQUEST_GLOVE_DISTANCE'
