<script>
export default {
  name: "Spinner",
  props: {
    isVisible: {
      type: Boolean,
      default: false
    }
  },
}
</script>

<template>
<div
    class="spinner"
    v-if="isVisible"
>

</div>
</template>

<style scoped>
.spinner {
  border: 6px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top-color: #3498db;
  width: 80px;
  height: 80px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

</style>
