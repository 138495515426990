export default [
    {
        path: '/assistant/new',
        name: 'newAssistant',
        component: () => import('./components/views/CreateAssistant.vue'),
        props: true,
        meta: {
            backNavigateTo: 'homeCHIAssistants',
        },
    },
    {
        path: '/custom',
        name: 'customAssistants',
        component: () => import('./components/views/CustomAssistants.vue'),
    },
    {
        path: '/custom/assistant/:_assistantId',
        name: 'customAssistant',
        component: () => import('./components/views/CustomAssistant.vue'),
        props: true,
    },
    {
        path: '/teaching',
        name: 'teachingAssistants',
        component: () => import('./components/views/TeachingAssistants.vue'),
    },
    {
        path: '/teaching/assistant/:_assistantId',
        name: 'teachingAssistant',
        component: () => import('./components/views/TeachingAssistant.vue'),
        props: true,
    },
    {
        path: '/assistant/:_assistantId/ad',
        name: 'assistantAd',
        component: () => import('./components/views/AssistantAdView.vue'),
        props: true,
    },
    {
        path: '/assistant/:_assistantId/thread',
        name: 'newChat',
        component: () => import('./components/views/AssistantStream.vue'),
        props: true,
    },
    {
        path: '/assistant/:_assistantId/thread/:thread_id',
        name: 'chat',
        component: () => import('./components/views/AssistantStream.vue'),
        props: true,
    },
    {
        name: '/quantumHRAssistant',
        path: '/quantumHRAssistant',
        component: () =>
            import(
                './components/views/QuantumHRAssistant/QuantumHRAssistant.vue'
            ),
    },
    {
        name: '/quantumStrategies',
        path: '/quantumStrategies',
        component: () =>
            import('./components/QuantumStrategies/QuantumStrategies.vue'),
    },
    {
        name: 'batchImportFiles',
        path: '/batchImport/:fileNamePrefix',
        /*
            imports all existing files as OpenAI files, prepends fileNamePrefix
         */
        component: () =>
            import('./components/views/BatchImportAssistantFiles.vue'),
        props: true,
    },
]
