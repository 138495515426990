<script>
import { onMounted, onUnmounted, ref, watch } from "vue";
import LTabs from "../layout/LTabs";
import LWrap from "../layout/LWrap";
import TextInput from "../forms/TextInput";
import UploadedImage from "./UploadedImage.vue";
import UploadImage from "./UploadImage.vue";
import { useRouter } from "vue-router";
import Button from "../forms/Button";
import config from "../../../config.cjs";
import { useStore } from "vuex";
import { LIST_UPLOADS } from "@/store/operations.js";

const { appId } = config;

export default {
    name: "ImagePicker",
    emits: ["select", "hide"],
    components: {
        Button,
        LTabs,
        LWrap,
        TextInput,
        UploadedImage,
        UploadImage,
    },
    props: {
        value: {
            type: String,
            default:
                "https://hybridintelligence.eu/api/placeholders/pop/batmanready.jpg",
        },
        message: {
            type: String,
            default: "",
        },
        clientId: {
            type: String,
            default: "",
        },
        defaultImageUrl: {
            type: String,
            default: "../../assets/placeholders/pop/batmanwins.jpg",
        },
        types: {
            type: Object,
            default: () => ({
                types: ["one", "two", "three"],
            }),
        },
        showLibrary: {
            type: Boolean,
            default: true,
        },
    },
    setup(props, { emit }) {
        const store = useStore();
        const router = useRouter();
        const loadingImages = ref(true);
        const previewExternalUrl = ref(false);
        const previewUrl = ref("");
        const refreshTabs = ref(0);
        const selectedTab = ref(0);
        const images = ref([]);

        const tabLabels = ["My Images", "Upload", "Custom"];
        if (!props.showLibrary) tabLabels.splice(0, 1);

        const handleKey = (e) => {
            if (e.keyCode === 27) hideImagePicker();
        };

        onMounted(() => document.addEventListener("keyup", handleKey));
        onUnmounted(() => document.removeEventListener("keyup", handleKey));

        if (props.showLibrary) {
            store
                .dispatch(LIST_UPLOADS, { appId })
                .then((response) => {
                    const { data } = response;
                    images.value = data?._images ?? [];
                })
                .catch((err) => {
                    console.log(824, err);
                })
                .finally(() => (loadingImages.value = false));
        }
        const onUploaded = (files) => {
            const imageSet = new Set(images.value ?? []);
            files.forEach((file) => {
                imageSet.add(file);
            });
            images.value = [...imageSet];
            selectedTab.value = 0;
            refreshTabs.value = Date.now();
        };

        const hideImagePicker = () => emit("hide");

        const onSelect = (image) => {
            emit("select", image);
            hideImagePicker();
        };

        const onRemoteUrl = (value, done) => {
            previewUrl.value = value.trim();
            if (done) previewExternalUrl.value = true;
        };
        watch(router.currentRoute, hideImagePicker);

        return {
            appId,
            images,
            loadingImages,
            previewExternalUrl,
            onRemoteUrl,
            onSelect,
            onUploaded,
            previewUrl,
            refreshTabs,
            selectedTab,
            tabLabels,
        };
    },
};
</script>
<template>
    <div
        class="imagePicker absolute transform -translate-x-1/2 -translate-y-1/2 left-1/2 w-[80vw] k15:w-[40vw] bg-white z-40 shadow-xl"
    >
        <LTabs
            :labels="tabLabels"
            :selected="selectedTab"
            :update="refreshTabs"
            :default-tab="0"
        >
            <template #one>
                <LWrap
                    v-if="images.length"
                    class="p-4 overflow-auto h-[60vh] max-w-[90vw]"
                >
                    <template #items>
                        <UploadedImage
                            class="w-[22%] m-1"
                            :class="{
                                'w-[90%]': images.length === 1,
                                'w-[46%]': images.length <= 4,
                                'w-[30%]': images.length <= 9,
                            }"
                            v-for="image in images"
                            :key="image"
                            :url="image"
                            :title="image"
                            @click="onSelect(image)"
                        >
                        </UploadedImage>
                    </template>
                </LWrap>
                <div v-else>
                    <span v-if="loadingImages">loading...</span>
                    <div
                        v-else
                        class="flex h-full my-24 w-full flex-col items-center justify-stretch"
                    >
                        <span> no images yet </span>
                    </div>
                </div>
            </template>

            <template #two>
                <UploadImage
                    class="min-w-[40vw]"
                    :message="message"
                    :_appId="appId"
                    @uploaded="onUploaded"
                ></UploadImage>
            </template>

            <template #three>
                <div class="w-full p-4 flex flex-col justify-center gap-y-8">
                    <Button
                        class="button"
                        :isAlt="true"
                        @click="onSelect(defaultImageUrl)"
                    >
                        <template #center>Use Default Image</template>
                    </Button>

                    <div>
                        <span>External Image Url</span>
                        <TextInput
                            placeholder="External Image Url"
                            hint="An image url is required"
                            :value="value"
                            icon="imageFrame"
                            @change="previewExternalUrl = true"
                            @input="onRemoteUrl"
                        ></TextInput>
                        <div class="flex justify-center">
                            <img
                                class="m-4 max-h-[200px] max-w-[200px]"
                                v-if="previewExternalUrl"
                                :src="previewUrl"
                                alt="preview image"
                                @load="onSelect(previewUrl)"
                            />
                        </div>
                    </div>
                </div>
            </template>
            <!--      <template #1>-->

            <!--        <UnsplashImages></UnsplashImages>-->

            <!--      </template>-->
        </LTabs>
    </div>
</template>

<style scoped></style>
