<script>
import LGamePage from '../layout/LGamePage.vue'

export default {
    name: 'WelcomePage',
    components: {
        LGamePage,
    },
}
</script>
<template>
  <LGamePage>
    <template #title>
      {{ $t('flashMatch.welcome') }}
      <h2 class="gamifiedh2">
        {{ $t('flashMatch.introHeader') }}
      </h2>
    </template>
    <template #default>
      <p
        class="text-md text-center"
        v-html="$t('flashMatch.introText')"
      />

      <img
        src="../../assets/mental.png"
        height="150"
        width="150"
        class="mt-8"
      >
    </template>
    <template #actions>
      <button
        class="button mt-12"
        @click="$router.push({ name: 'flash-match-search-intro' })"
      >
        {{ $t('flashMatch.actions.start') }}
      </button>
    </template>
  </LGamePage>
</template>
