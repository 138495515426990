
<script>
import LSection from "@/components/layout/LSection.vue";

export default {
  components: {LSection},
  name: "Footer",
  setup() {
    console.log("Footer.vue setup()");
    return {};
  },
};
</script>
<template>
  <footer class="bg-slate-200  text-black mt-12">
    <LSection>
      <div
          class="
              mx-auto
              py-10
              flex flex-col items-start
              h6:flex-row
              gap-12 h6:gap-0
      ">
        <div class="flex-1 text-xs">
          <img
              src="@/assets/images/Logo_CHI.png"
              alt="DIGI-Q Logo"
              class="h-14"
          />
        </div>

        <div class="flex-1 ">
          <img src="../../assets/images/euflag.1d437774.png" class="h-14"/>
          <p class="text-xs">
            This project has received funding from the European Union.
          </p>
          <div class="flex">
            <label class="text-xs"
            >Check
              <a
                  class="text-blue-600 text-xs"
                  href="/chi-client-framework/src/assets/PrivacyPolicyAgreement.pdf"
                  target="_blank"
              >
                privacy policy</a
              ></label
            >
          </div>
          <div class="flex">
            <label class="text-xs"
            >Check
              <a
                  class="text-blue-600 text-xs"
                  href="/chi-client-framework/src/assets/PersonalDataAgreement.pdf"
                  target="_blank"
              >
                terms and conditions</a
              ></label
            >
          </div>
          <div class="flex">
            <label class="text-xs">
              Check
              <a
                  class="text-blue-600 text-xs"
                  href="/chi-client-framework/src/assets/CookiePolicy.pdf"
                  target="_blank"
              >
                Cookie Policy
              </a>
              <!--                <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 30 30"
                width="15px"
                height="15px"
                class=""
              >
                <path
                  fill="#ffffff"
                  d="M 25.980469 2.9902344 A 1.0001 1.0001 0 0 0 25.869141 3 L 20 3 A 1.0001 1.0001 0 1 0 20 5 L 23.585938 5 L 13.292969 15.292969 A 1.0001 1.0001 0 1 0 14.707031 16.707031 L 25 6.4140625 L 25 10 A 1.0001 1.0001 0 1 0 27 10 L 27 4.1269531 A 1.0001 1.0001 0 0 0 25.980469 2.9902344 z M 6 7 C 4.9069372 7 4 7.9069372 4 9 L 4 24 C 4 25.093063 4.9069372 26 6 26 L 21 26 C 22.093063 26 23 25.093063 23 24 L 23 14 L 23 11.421875 L 21 13.421875 L 21 16 L 21 24 L 6 24 L 6 9 L 14 9 L 16 9 L 16.578125 9 L 18.578125 7 L 16 7 L 14 7 L 6 7 z"
                />
              </svg>-->
            </label>
          </div>
        </div>
      </div>
    </LSection>
  </footer>
</template>

<style scoped></style>
