<template>
  <button
      :class="['border-assistant_primary-500 border bg-white w-fit flex-shrink-0 p-2 px-4 text-assistant_primary-500 font-bold rounded-md shadow-md',
            {'bg-opacity-70  ': disabled},
            {'hover:bg-opacity-80 duration-300  active:scale-[98%]': !disabled},
            customClass]"
      :disabled="disabled"
  >
    <i v-if="iconClass" :class="iconClass" class="mr-2"></i>
    {{ buttonText }}
  </button>
</template>

<script>
export default {
  name: 'SecondaryButton',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    buttonText: {
      type: String,
      required: true,
    },
    customClass: {
      type: String,
      default: ''
    },
    iconClass: {
      type: String,
      default: ''
    }
  }
}
</script>
