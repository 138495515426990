import {
    _PUT_STATUS,
    ACTIVATE_ACCOUNT,
    CHANGE_PASSWORD,
    RESET_PASSWORD,
    GET_USER,
    LIST_USERS,
    LOGIN,
    LOGOUT,
    REQUEST_ACCOUNT,
    REQUEST_CHANGE_EMAIL,
    REQUEST_FORGET_USER,
    REQUEST_PASSWORD_RESET,
    _RESET_ASSETS,
    VERIFY_ACTIVATE_ACCOUNT_TOKEN,
    VERIFY_RESET_PASSWORD_TOKEN,
    RESUME_SESSION
} from "@/store/operations.js";
import {throttleBufferedAction} from "@/store/lib/throttledBufferedAction";
import axios from "@/plugins/axios";
import {state} from "@/store/store";
import {handleResponse} from "@/store/actions/lib/handleResponse";
import {getDataTypeActionUrl} from "@/store/actions/lib/getDataTypeActionUrl.js";
import {getDocumentActionUrl} from "@/store/actions/lib/getDocumentActionUrl.js";
import {getAppActionUrl} from "@/store/actions/lib/getAppActionUrl.js";

export default {
// api/actions/:_appId/action/:endpoint
    [LIST_USERS](context, {offset, pageSize, filter}) {
        return throttleBufferedAction({
            action: axios.post,
            url: getDataTypeActionUrl({
                _appId: 'app',
                _dataType: 'Account',
                endpoint: 'list'
            }),
            params: {
                offset,
                pageSize,
                filter: JSON.stringify(filter)
            }
        })
            .then(handleResponse(context, LIST_USERS))
    },
    [GET_USER](context, _id) {
        return throttleBufferedAction({
            action: axios.get,
            url: getDocumentActionUrl({
                _appId: 'app',
                _dataType: 'Account',
                _id,
                endpoint: 'get'
            })
        })
            .then(handleResponse(context, GET_USER))
    },
    [LOGIN](context, {email, password}) {
        return axios.post(
            getDataTypeActionUrl({
                _appId: 'app',
                _dataType: 'Account',
                endpoint: 'login'
            }),
            {
                _email: email,
                password
            },
        )
            .then(async response => {
                context.commit(
                    LOGIN,
                    response.data
                )
                context.commit(_PUT_STATUS, {event: LOGIN, result: response.data})

                return response
            })
    },
    // [ENTER_SESSION_KEY](context, {key}) {
    //     return axios.post(
    //         getUserAccountActionUrl(key, 'getAnonymousSession'),
    //     )
    //         .then(async response => {
    //             context.commit(
    //                 LOGIN,
    //                 response.data
    //             )
    //             context.commit(_PUT_STATUS, {event: LOGIN, result: response.data})
    //
    //             return response
    //         })
    // },
    // [ENTER_SESSION_KEY_WITH_USER_ID](context, {key}) {
    //     return axios.post(
    //         getUserAccountActionUrl(key, 'getSingleUseSession'),
    //     )
    //         .then(async response => {
    //             context.commit(
    //                 LOGIN,
    //                 response.data
    //             )
    //             context.commit(_PUT_STATUS, {event: LOGIN, result: response.data})
    //
    //             return response
    //         })
    // },
    [LOGOUT](context) {
        context.commit(_RESET_ASSETS)
        context.commit(LOGOUT)
        return axios.post(
            getDataTypeActionUrl({
                _appId: 'app',
                _dataType: 'Account',
                endpoint: 'logout'
            }),
        )
            .then(() => {
                context.commit(_PUT_STATUS, {event: LOGOUT})
            })
            .finally(() =>
                context.commit(LOGOUT)
            )
    },
    [CHANGE_PASSWORD](context, {_id, passphrase, newPassphrase}) {

        // not implemented, but should be. honestly. it would be amiss this function, if were to remain unimplmented. one day...
        return axios.put(
            getDocumentActionUrl({
                _appId: 'app',
                _dataType: 'Account',
                _id,
                endpoint: 'changePassword',
            }),
            {
                update:
                    {
                        passphrase,
                        newPassphrase
                    }
            }
        )
            .then(handleResponse(context, CHANGE_PASSWORD))
    },
    [REQUEST_ACCOUNT](context, {_email, _firstName, _lastName, _redirectTo}) {
        return throttleBufferedAction({
            action: axios.post,
            url: getDataTypeActionUrl({
                _appId: 'app',
                _dataType: 'Account',
                endpoint: 'requestAccount'
            }),
            params: {
                _email,
                _firstName,
                _lastName,
                _redirectTo
            }
        })
            .then(handleResponse(context, REQUEST_ACCOUNT))
    },
    [REQUEST_FORGET_USER](context, _id) {
        // todo: implement on demand
        return axios.post(
            getDocumentActionUrl({
                _appId: 'app',
                _dataType: 'Account',
                _id,
                endpoint: 'forgetMe'
            }),
        )
            .then(handleResponse(context, REQUEST_FORGET_USER))
    },
    [REQUEST_PASSWORD_RESET](context, {_email, _redirectTo}) {
        return axios.post(
            getDataTypeActionUrl({
                _appId: 'app',
                _dataType: 'Account',
                endpoint: 'requestResetPassword'
            }),
            {
                _email,
                _redirectTo
            }
        )
            .then(handleResponse(context, REQUEST_PASSWORD_RESET))
    },
    [REQUEST_CHANGE_EMAIL](context, {_id, newEmail, _redirectTo}) {
        return axios.post(
            getDocumentActionUrl({
                _appId: 'app',
                _dataType: 'Account',
                _id,
                endpoint: 'requestChangeEmail'
            }),
            {
                newEmail,
                _redirectTo
            }
        )
            .then(handleResponse(context, REQUEST_CHANGE_EMAIL))
    },
    // with token
    [ACTIVATE_ACCOUNT](
        context,
        {
            activateAccountToken,
            firstName,
            lastName,
            title,
            password,
        }
    ) {
        return axios.post(
            getDataTypeActionUrl({
                _appId: 'app',
                _dataType: 'Account',
                endpoint: 'activateAccountWithToken'
            }),
            {
                activateAccountToken,
                title,
                _firstName: firstName,
                _lastName: lastName,
                password,
            }
        )
            .then(handleResponse(context, ACTIVATE_ACCOUNT))
    },
    [VERIFY_ACTIVATE_ACCOUNT_TOKEN](
        context,
        {activateAccountToken},
    ) {
        return axios.post(
            getAppActionUrl({
                _appId: 'app',
                endpoint: 'verifyToken'
            }),
            {
                tokenName: 'activateAccountToken',
                token: activateAccountToken
            }
        )
            .then(handleResponse(context, VERIFY_ACTIVATE_ACCOUNT_TOKEN))
    },
    [VERIFY_RESET_PASSWORD_TOKEN](
        context,
        {resetPasswordToken},
    ) {
        return axios.post(
            getAppActionUrl({
                _appId: 'app',
                endpoint: 'verifyToken'
            }),
            {
                tokenName: 'resetPasswordToken',
                token: resetPasswordToken
            }
        )
            .then(handleResponse(context, VERIFY_RESET_PASSWORD_TOKEN))
    },
    [RESET_PASSWORD](context, {resetPasswordToken, newPassword}) {
        return axios.post(
            getDataTypeActionUrl({
                _appId: 'app',
                _dataType: 'Account',
                endpoint: 'resetPasswordWithToken'
            }),
            {
                resetPasswordToken,
                newPassword
            }
            )
            .then(handleResponse(context, RESET_PASSWORD))
    },
    // [CONFIRM_DOWNLOAD_MY_DATA](context, {downloadMyDataToken}) {
    //     return axios.post(
    //         getUserAccountConfirmationUrl('downloadMyData', downloadMyDataToken),
    //     )
    //         .then(handleResponse(context, CONFIRM_DOWNLOAD_MY_DATA))
    // },
    // [CONFIRM_FORGET_ABOUT_ME](context, {forgetAboutMeToken}) {
    //     return axios.post(
    //         getUserAccountConfirmationUrl('forgetAboutMe', forgetAboutMeToken),
    //     )
    //         .then(handleResponse(context, CONFIRM_FORGET_ABOUT_ME))
    // },
    // [CONFIRM_CHANGE_EMAIL](context, {changeEmailToken}) {
    //     return axios.post(
    //         getUserAccountConfirmationUrl('changeEmail', changeEmailToken),
    //     )
    //         .then(handleResponse(context, CONFIRM_CHANGE_EMAIL))
    // },
    // [ACTIVATE_NEW_EMAIL](context, {newEmailToken}) {
    //     return axios.post(
    //         getUserAccountConfirmationUrl('activateNewEmail', newEmailToken),
    //     )
    //         .then(handleResponse(context, ACTIVATE_NEW_EMAIL))
    // },
    [RESUME_SESSION](context) {
        if (state.roleDebounce && state.roleDebounce > Date.now()) {
            return new Promise(resolve => resolve())
        }
        state.roleDebounce = Date.now() + (1000 * 60 * 20)
        return axios.post(
            getDataTypeActionUrl({
                _appId: 'app',
                _dataType: 'Account',
                endpoint: "resumeSession"
            }),
        )
            .then((response) => {
                context.commit(RESUME_SESSION, response.data)
                context.commit(_PUT_STATUS, {event: RESUME_SESSION, result: response.data})
            })
    },
}