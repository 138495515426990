<script>
import { computed, isRef } from 'vue'

export default {
    name: 'LFieldError',
    props: {
        message: {
            type: String,
        },
    },
    setup(props) {
        const lines = computed(() => {
            const lines =
                (isRef(props.message) ? props.mesasge?.value : props.message) ||
                ''
            if (lines) {
                if (lines?.split) return lines.split('\n')
                else return lines
            }
            return lines
        })
        return {
            lines,
        }
    },
}
</script>
<template>
    <div class="LFieldError">
        <div class="fieldError__message text-red-800">
            <slot>
                <p
                    v-for="(line, index) in lines"
                    :key="index"
                >
                    {{ line }}
                </p>
            </slot>
        </div>
    </div>
</template>

<style scoped></style>
