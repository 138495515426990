import {decapitalize} from '@mehimself/cctypehelpers';

const checkAppRoute = (route, appId) => {
    if (!route.path?.length) throw new Error(`The '' app route path (${appId}) is reserved for your HomeView.vue`)

    const reservedRouteNames = [
        'api',
        `layout`,
        `home`
    ]

    if (!route.name) throw new Error(`App route path ${route.path} must have a name (appId: ${appId}).`)
    if (route.name !== decapitalize(route.name)) throw new Error(`App route name ${route.name} must be decapitalized (appId: ${appId}).`)
    if (reservedRouteNames.includes(route.name?.toLowerCase())) throw new Error(`App route name ${route.name} cannot use the name of a root route (appId: ${appId}).`)
}

export const rigSinglePageAppEntryRoute = async (routes, appId) => {
    try {

        const LHome = (await import(`@/../chi-${appId}/components/views/layout/LHome.vue`)).default
        const Home = (await import(`@/../chi-${appId}/components/views/Home.vue`)).default

        routes.forEach(route => checkAppRoute(route, appId))

        const homeRoute = {
            path: '/',
            name: 'layout',
            component: LHome,
            children: [
                {
                    path: '',
                    name: 'home',
                    component: Home,
                },
                ...routes
            ]
        }
        return homeRoute

    } catch (err) {
        console.log(111, 'checkAppRoute', err)
        throw err
    }


}
