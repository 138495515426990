<script>
import {computed} from "vue";

export default {
  name: 'StepComponent',
  props: {
    stepNumber: {
      required: true,
    },
  },
  setup(props) {
    const stepName = computed(() => `step${props.stepNumber}`);
    return {
      stepName,
    }
  }
}

</script>

<template>
  <div
      v-if="$slots[stepName]"
      class="p-3 pt-8 rounded-lg gap-2 max-w-44 flex flex-col border-cognikitBlue border relative items-center text-center shadow-lg"
  >
    <div
        class="rounded-full absolute text-shadow circle-shadow top-0 transform -translate-y-1/2 h-16  w-16 !bg-cognikitBlue grid shadow-cognikitBlue !text-white place-content-center shadow gamifiedh1"
    >
      {{ stepNumber }}
    </div>
    <div class="gamifiedp">
      <slot :name="stepName"></slot>
    </div>
  </div>
</template>


<style scoped>
/* Your component specific styles here */
</style>
