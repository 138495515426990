import * as docOperations from "@/store/operations.js";

export const normalizeDocumentOperationKeys = (operations) => {
    if (operations.list) operations[`listDocuments`] = operations.list ?? docOperations[`LIST_DOCUMENTS`]
    if (operations.get) operations[`getDocument`] = operations.get ?? docOperations[`LOAD_DOCUMENT`]
    if (operations.create) operations[`createDocument`] = operations.create ?? docOperations[`CREATE_DOCUMENT`]
    if (operations.update) operations[`updateDocument`] = operations.update ?? docOperations[`PATCH_DOCUMENT`]
    if (operations.publish) operations[`publishDocument`] = operations.publish ?? docOperations[`PUBLISH_DOCUMENT`]
    if (operations.retract) operations[`retractDocument`] = operations.retract ?? docOperations[`RETRACT_DOCUMENT`]
    if (operations.delete) operations[`deleteDocument`] = operations.delete ?? docOperations[`DELETE_DOCUMENT`]

    delete operations.list
    delete operations.get
    delete operations.create
    delete operations.update
    delete operations.publish
    delete operations.retract
    delete operations.delete
}