import { createStore } from "vuex";
import actions from './actions/actions.js';
import mutations from './mutations.js';
import { reactive } from "vue";

export const state = reactive({
  appId: 'app',
  status: {},
  users: {},
  docs: {},
  documentFilter: null,
  UI: {}, // global UI states
  user: null,
  account: null,
  navigationHistory: [],
});
export default createStore({
  state,
  mutations,
  actions,
});
