import {
    CREATE_CHI_VECTOR_STORE,
    DELETE_CHI_VECTOR_STORE,
    PATCH_CHI_VECTOR_STORE,
    ADD_CHI_VECTOR_STORE_FILES,
    REPLACE_CHI_VECTOR_STORE_FILE,
    DELETE_CHI_VECTOR_STORE_FILE,
    LOAD_CHI_VECTOR_STORE,
    STREAM_CHI_VECTOR_STORE_MODIFICATION_STATE,
} from "../operations.js";
import {_RESET_STATUS} from "@/store/operations.js";
import axios from "@/plugins/axios";
import {throttleBufferedAction} from "@/store/lib/throttledBufferedAction";
import {handleResponse} from "@/store/actions/lib/handleResponse";
import {getDocumentActionUrl} from "@/store/actions/lib/getDocumentActionUrl.js";
import {getDataTypeActionUrl} from "@/store/actions/lib/getDataTypeActionUrl.js";

const _appId = 'assistants'

export default {
    [LOAD_CHI_VECTOR_STORE](context, {_id}) {
        context.commit(_RESET_STATUS, LOAD_CHI_VECTOR_STORE)
        let url = getDocumentActionUrl({
            _appId,
            _dataType: 'VectorStore',
            _id,
            endpoint: 'get',
        })
        return throttleBufferedAction({
            action: axios.get,
            url,
        })
            .then(handleResponse(context, LOAD_CHI_VECTOR_STORE))
    },
    [CREATE_CHI_VECTOR_STORE](context, attributes) {
        context.commit(_RESET_STATUS, CREATE_CHI_VECTOR_STORE)
        let url = getDataTypeActionUrl({
            _appId,
            _dataType: 'VectorStore',
            endpoint: 'create',
        })
        return throttleBufferedAction({
            action: axios.post,
            url,
            params: {
                ...attributes,
            },
        })
            .then(handleResponse(context, CREATE_CHI_VECTOR_STORE))
    },
    [PATCH_CHI_VECTOR_STORE](context, {_id, attributes, pipeName}) {
        context.commit(_RESET_STATUS, PATCH_CHI_VECTOR_STORE)
        let url = getDocumentActionUrl({
            _appId,
            _dataType: 'VectorStore',
            _id,
            endpoint: 'patch',
        })
        const params = {
            attributes
        }
        if (pipeName) params.pipeName = pipeName
        return throttleBufferedAction({
            action: axios.patch,
            url,
            params,
        })
            .then(handleResponse(context, PATCH_CHI_VECTOR_STORE))
    },
    [DELETE_CHI_VECTOR_STORE](context, {_id}) {
        context.commit(_RESET_STATUS, DELETE_CHI_VECTOR_STORE)
        let url = getDocumentActionUrl({
            _appId,
            _dataType: 'VectorStore',
            _id,
            endpoint: 'delete',
        })
        return throttleBufferedAction({
            action: axios.delete,
            url,
        })
            .then(handleResponse(context, DELETE_CHI_VECTOR_STORE))
    },
    [ADD_CHI_VECTOR_STORE_FILES](context, {_id, files}) {
        context.commit(_RESET_STATUS, ADD_CHI_VECTOR_STORE_FILES)
        let url = getDocumentActionUrl({
            _appId,
            _dataType: 'VectorStore',
            _id,
            endpoint: 'addFiles',
        })
        return throttleBufferedAction({
            action: axios.put,
            url,
            params: {
                files,
            },
        })
            .then(handleResponse(context, ADD_CHI_VECTOR_STORE_FILES))
    },
    [REPLACE_CHI_VECTOR_STORE_FILE](context, {fileId, file}) {
        context.commit(_RESET_STATUS, REPLACE_CHI_VECTOR_STORE_FILE)
        let url = getDocumentActionUrl({
            _appId,
            _dataType: 'File',
            _id: fileId,
            endpoint: 'replace'
        })
        return throttleBufferedAction({
            action: axios.put,
            url,
            params: {
                file,
            },
        })
            .then(handleResponse(context, REPLACE_CHI_VECTOR_STORE_FILE))
    },
    [STREAM_CHI_VECTOR_STORE_MODIFICATION_STATE](context, {_id, pipeName}) {
        context.commit(_RESET_STATUS, STREAM_CHI_VECTOR_STORE_MODIFICATION_STATE)
        let url = getDocumentActionUrl({
            _appId,
            _dataType: 'VectorStore',
            _id,
            endpoint: 'streamState',
        })
        return throttleBufferedAction({
            action: axios.post,
            url,
            params: {
                pipeName,
            },
        })
            .then(handleResponse(context, STREAM_CHI_VECTOR_STORE_MODIFICATION_STATE))
    },
    [DELETE_CHI_VECTOR_STORE_FILE](context, {_id, fileId}) {
        context.commit(_RESET_STATUS, DELETE_CHI_VECTOR_STORE_FILE)
        let url = getDocumentActionUrl({
            _appId,
            _dataType: 'VectorStore',
            _id,
            endpoint: 'deleteFile',
        })
        return throttleBufferedAction({
            action: axios.post,
            url,
            params: {
                fileId,
            }
        })
            .then(handleResponse(context, DELETE_CHI_VECTOR_STORE_FILE))
    },
}

