export const useLocalStorage = (key, defaultValue, onSet, onRemove) => {
    const remove = () => {
        localStorage.removeItem(key)
        if (onRemove) onRemove(key)
    }
    const set = (newValue) => {
        let value = newValue
        if (newValue === undefined) {
            if (defaultValue === undefined) {
                return remove()
            } else {
                value = defaultValue
            }
        } else {
            value = JSON.stringify(newValue)
        }
        localStorage.setItem(key, value)
        if (onSet) onSet(key, value)
    }
    const get = () => {
        let entry = localStorage.getItem(key)
        if (entry !== undefined) {
            return JSON.parse(localStorage.getItem(key))
        }
    }

    if (get() === undefined) set(defaultValue)

    return {
        getLocalStorageValue: get,
        setLocalStorageValue: set,
    }
}
