export const LAV_LINKET_KORT = 'LAV_LINKET_KORT'
export const WEBSOCKET = 'WEBSOCKET'

// ASSET
export const LIST_ASSETS = 'LIST_ASSETS'
export const SYNC_ASSETS = 'SYNC_ASSETS'
export const APPLY_DELTA = 'APPLY_DELTA'
export const GET_ASSET = 'GET_ASSET'
export const DELETE_ASSET = 'DELETE_ASSET'

// UPLOAD
export const LIST_UPLOADS = 'LIST_UPLOADS'
export const UPLOAD = 'UPLOAD'

// SESSIONS
export const ENTER_SESSION_KEY = 'ENTER_SESSION_KEY'
// INTERNAL MUTATIONS
export const _SET_UI_STATE = '_SET_UI_STATE'
export const _PUT_STATUS = '_PUT_STATUS'
export const _RESET_STATUS = '_RESET_STATUS'
export const _RESET_ASSETS = '_RESET_ASSETS'
export const COPY_LONG_URL = 'COPY_LONG_URL'

// MOCKUP
export * from './operations/documentOperations.js'
export * from '../../chi-assistants/operations.js'
export * from '../../chi-app/operations.js'
export * from '../../chi-delphi/operations.js'
export * from '../../chi-creavisions/operations.js'
export * from '../../chi-ccf/operations.js'
export * from '../../chi-showcase/operations.js'
