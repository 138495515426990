<script>
import LSection from "@/components/layout/LSection.vue";
import {useRouter} from "vue-router";
import {useUserPermissions} from "@/composables/useUserPermissions";
import IconMagnify from "@/components/icons/Magnify.vue";

export default {
  name: "RecordsMenu",
  components: {
    IconMagnify,
    LSection,
  },
  setup() {
    const router = useRouter()
    const {isLoggedIn, account, user} = useUserPermissions();

    const onNavigate = route => {
      router.push(route)
    }

    return {
      account,
      isLoggedIn,
      user,

      onNavigate,
    };
  },
}
</script>

<template>
  <div class="recordsMenu">
    <nav
        class="
              menuBar
              w-full
              h-10
              text-gray-700
              text-base font-medium
              dark:bg-gray-900
          "
    >
      <div class="w-full flex flex-wrap justify-between items-center">
        <div class="flex gap-4 text-lg">
          <div class="flex items-center">
            <span>REC</span>
            <span
                class="text-blue-300 -mx-2 pr-0.5"
            ><IconMagnify
                class="text-normal relative left-1 top-1"
                :size="32"
              ></IconMagnify></span>
            <span>RDS</span>
          </div>
        </div>
        <div class="flex gap-4 text-lg">
          <a
              :class="{'text-black font-bold': $route.name === 'recordsWelcome'}"
              @click="onNavigate('/APMM/Records')"
          >
            Welcome
          </a>
        </div>
        <div class="flex gap-4 text-lg">
          <a
              :class="{'text-black font-bold': $route.name === 'createNew'}"
              class="isDisabled"
          >
            Create New
          </a>
        </div>
        <div class="flex gap-4 text-lg">
          <a
              :class="{'text-black font-bold': $route.name === 'recordsSearch'}"
              @click="onNavigate('/APMM/Records/search')"
          >
            Search and Modify
          </a>
        </div>
        <div class="flex gap-4 text-lg">
          <a
              :class="{'text-black font-bold': $route.name === 'recordsGuide'}"
              @click="onNavigate('/APMM/Records/guide')"
          >
            Guide to RECORDS
          </a>
        </div>
        <div class="flex gap-4 text-lg">
          <a
              :class="{'text-black font-bold': $route.name === 'recordsFileImport'}"
              @click="onNavigate('/APMM/Records/fileImport')"
          >
            File Import
          </a>
        </div>
      </div>
    </nav>
  </div>
</template>


