import {reactive, ref, toRefs, watch} from "vue";
import {isArray, isObject} from '@mehimself/cctypehelpers';
import {isJSIdentifier} from '@mehimself/cctypehelpers';
import {isStringWithLength} from '@mehimself/cctypehelpers';

const debug = true
const ingestAttributeName = attributeName => {
    if (!isJSIdentifier(attributeName, true)) throw new Error(`attributeName must be a non-empty string.`)
    return attributeName.trim()
}
const defaultOptions = {
    isOptional: false,
    defaultValue: '',
    tests: [],
}
const ingestOptions = options => {
    options = options ?? {}
    options.tests = ingestTests(options.tests)
    if (!isObject(options)) throw new Error(`options must be an object.`)
    Object.assign(options, defaultOptions)
    return options
}

const ingestTests = tests => {
    tests = tests || []
    if (!isArray(tests)) throw new Error(`tests must be an array.`)
    tests.forEach(test => {
        if (!isObject(test)) throw new Error(`test must be an object.`)
        if (!test.title) throw new Error(`test.title is required`)
        if (!test.validator) throw new Error(`test.validator is required`)
        test.errorMessage = test.errorMessage || `Invalid value.`
    })
    return tests
}
const ingestValue = (value, options) => {
    const {
        isOptional,
    } = options

    if (value === undefined || value === null && !isOptional) throw new Error(`Value is required.`)
    return value
}
const verifyValue = (attributeName, value, tests) => {
    tests.forEach(test => {
        const {
            title,
            validator,
            errorMessage,
        } = test
        const pass = validator(value)
        if (!pass) throw new Error(`${attributeName} failed test: ${title}. ${errorMessage}`)
    })
}

// >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

export const useFormField = (documentRef, attributeName, options) => {
    attributeName = ingestAttributeName(attributeName)
    options = ingestOptions(options)
    const {
        isOptional,
        defaultValue,
        tests,
    } = options

    if (debug) console.log(111, `useFormField('${attributeName}')`, {isOptional, defaultValue, tests})
    if (!options.isOptional) {
        options.tests.push({
            title: 'isRequired',
            validator: value => isStringWithLength(value, true),
            errorMessage: `Cannot be empty.`,
        })
    }

    const context = reactive({
        isWaitingForDocument: true,
        isValid: false,
        isPristine: false,
        value: null,
        lastPristineValue: null,
        error: null,
    })

    // on local change
    watch(context, (newValue, oldValue) => {
        context.error = null
        newValue = ingestValue(newValue, options)
        if (newValue !== oldValue)
            try {
                context.isValid = false
                verifyValue(attributeName, newValue, tests)
                context.isValid = true
                context.isPristine = context.value === context.lastPristineValue
            } catch (error) {
                context.error = error
            }
    })

    // on server value change
    watch(documentRef, (newDocument) => {
        let newValue = newDocument?.[attributeName]
        context.isPristine = newDocument && newValue === context.value

        if (!context.isPristine) {
            context.value = newValue
            context.lastPristineValue = newValue
            context.error = null
            context.isValid = true // sucks if tests are misconfigured
            context.isPristine = true
        }
    })

    const revertValue = () => {
        context.value = context.lastPristineValue
        context.isPristine = true
    }

    return {
        ...toRefs(context),
        revertValue,
    }
}