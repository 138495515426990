import {
    isArrayOfStrings,
    isJSIdentifier,
    isObject,
} from '@mehimself/cctypehelpers'
import { getCurrentInstance, isRef } from 'vue'

const filterTerms = [
    // sync with ccneo4jdatabase/router/controllers/dataType/listDocuments/lib/part_expandFilterExpression.js

    '=',
    '!=',
    '>',
    '>=',
    '<',
    '<=',
    'contains',
    '!contains',
    'in',
    '!in',
    'starts_with',
    '!starts_with',
    'ends_with',
    '!ends_with',
]

const validateFilterAttribute = (attribute, path) => {
    const { field, operator, value } = attribute
    if (!isJSIdentifier(field))
        throw new Error(`${path}.field must be a valid JavaScript identifier`)
    if (!filterTerms.includes(operator))
        throw new Error(
            `${path}.operator must be one of ${filterTerms.join(', ')}`
        )
    if (operator === 'in' && !isArrayOfStrings(value))
        throw new Error(`${path}.operator "in" requires an array of strings`)
}
export const validateFilter = (filter, testPath = 'filter') => {
    /*
            check if filter structure is acceptable
    
            Document filter syntax:

            FILTER: {
              DISJUNCTION | CLAUSE
            }
            CLAUSE: { // fx. document.age > 30
              field: "age",
              operator: "greater_than",
              value: 30
            }
            DISJUNCTION: {
                type: "OR|AND", // default is "AND"
                filters: [
                    FILTER,
                    ...
                ]
            }

         */
    if (!isObject(filter)) throw new Error(`${testPath} must be an object`)
    if (Object.keys(filter).length === 0) return filter

    const { type, filters, field, operator, value } = filter
    if (type && filters) {
        // multi-attribute filter
        if (type === 'AND' || type === 'OR') {
            filters.forEach((f, i) =>
                validateFilter(f, `${testPath}.filters[${i}]`)
            )
        } else {
            throw new Error('filter.type must be "AND" or "OR"')
        }
    } else if (field && operator && value) {
        // attribute filter
        validateFilterAttribute(filter, testPath)
    } else {
        throw new Error(
            'filter must have either a type and filters array, or afield, an operator, and a value'
        )
    }
    return filter
}
export const ingestFilterRef = ({ _appId, _dataType, filter }) => {
    try {
        if (!isRef(filter)) throw new Error('filter must be a ref')
        if (!isObject(filter.value))
            filter.value = {
                type: 'AND',
                filters: [
                    {
                        field: '_appId',
                        operator: 'equals',
                        value: _appId,
                    },
                    {
                        field: '_dataType',
                        operator: 'equals',
                        value: _dataType,
                    },
                ],
            }
        filter.value = validateFilter(filter.value)
    } catch (err) {
        throw new Error(
            `${getCurrentInstance()?.type?.name} .. ingestFilterRef: ${
                err.message
            }`
        )
    }
    return filter // ref
}
