<script setup>

</script>

<template>
  <div class="">
    <svg class="w-10 h-10 pl-1 text-wai-200 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
         fill="currentColor" viewBox="0 0 24 24">
      <path fill-rule="evenodd" d="M8.6 5.2A1 1 0 0 0 7 6v12a1 1 0 0 0 1.6.8l8-6a1 1 0 0 0 0-1.6l-8-6Z"
            clip-rule="evenodd"/>
    </svg>
  </div>
</template>

<style scoped>

</style>