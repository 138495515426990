<template>
  <button
      :class="['bg-assistant_primary-500 w-fit flex p-2 justify-center text-center px-4 flex-shrink-0 text-white font-bold rounded-md shadow-md',
            {'opacity-50 !bg-gray-500': disabled},
            {'hover:bg-assistant_primary-600  duration-300  active:scale-[98%]': !disabled},
            customClass]"
      :disabled="disabled"
  >
    <i v-if="icon" class="mr-1">
      <svg class="w-6 h-6 text-white pr-1 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
           fill="none" viewBox="0 0 24 24">
        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="M5 12h14m-7 7V5"/>
      </svg>

    </i>
    {{ buttonText }}
  </button>
</template>

<script>
export default {
  name: 'PrimaryButton',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    buttonText: {
      type: String,
      required: true,
    },
    customClass: {
      type: String,
      default: ''
    },
    icon: {
      type: Boolean,
      default: false
    }
  }
}
</script>
