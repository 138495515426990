export default [
    {
        target: '3',
        blockId: 'SZ_1',
        // first two are trials not scored or indexed
        stimuli: [
            '1',
            '7',
            '1',
            '0',
            '5',
            '7',
            '4',
            '3',
            '9',
            '8',
            '2',
            '6',
            '3',
            '4',
            '7',
            '5',
            '0',
            '8',
            '3',
            '1',
            '6',
            '9',
            '2',
            '3',
            '3',
            '7',
            '3',
            '8',
            '2',
            '6',
            '1',
            '3',
            '4',
            '5',
            '9',
            '0',
            '3',
            '0',
            '4',
            '9',
            '5',
            '7',
        ]
    },
    {
        target: '3',
        blockId: 'SZ_2',
        // first two are trials and not scored or indexed
        stimuli: [
            '4',
            '9',
            '2',
            '4',
            '3',
            '5',
            '7',
            '8',
            '3',
            '1',
            '9',
            '0',
            '6',
            '0',
            '1',
            '4',
            '9',
            '6',
            '2',
            '5',
            '3',
            '3',
            '7',
            '8',
            '8',
            '0',
            '4',
            '9',
            '7',
            '3',
            '2',
            '6',
            '1',
            '3',
            '5',
            '7',
            '2',
            '5',
            '1',
            '9',
            '3',
            '6',
        ]
    }
]