<script>
import {computed, ref} from "vue";
import DropdownSingle from "@/components/forms/DropdownSingle.vue";
import Button from "@/components/forms/Button.vue";

export default {
  name: "Priorities",
  emits: ['submit'],
  components: {
    Button,
    DropdownSingle,
  },
  setup(props, {emit}) {
    const vDefault = 0
    const points = 100
    const available = ref(points)
    const options = computed(() => [...Array((available.value + 10) / 10)]
        .map((n, i) => String(i * 10))
    )

    const priorities = ref([vDefault, vDefault, vDefault, vDefault, vDefault, vDefault])

    const onVChange = index => value => {
      index = index - 1
      const wantsMore = value > priorities.value[index]
      if (wantsMore && available.value <= 0) return

      priorities.value[index] = Math.min(value, available.value)

      available.value = priorities.value.reduce((sum, v = 0) => sum - v, 100)
    }

    const onSubmit = () => {
      if (available.value) return
      emit('submit', priorities.value.map(v => v/100))
    }

    return {
      available,
      options,
      priorities,

      onVChange,
      onSubmit,
    }
  }
}
</script>

<template>
  <div class="priorities">
    <div class="w-full flex flex-col gap-4">
      <div class="flex-grow flex items-center gap-4">
        <label for="v1" class="flex-grow">
          Young people's well-being
          <span>[{{ priorities[0] }} points]</span>

        </label>
        <DropdownSingle
            :items="options"
            v-model="priorities[0]"
            :title="priorities[0]"
            :showSearch="false"
            @select="value => onVChange(1)(value)"
        ></DropdownSingle>
      </div>
      <div class="flex-grow flex items-center gap-4">
        <label for="v2" class="flex-grow">
          Labour shortage
          <span>[{{ priorities[1] }} points]</span>
        </label>
        <DropdownSingle
            :items="options"
            v-model="priorities[1]"
            :title="priorities[1]"
            :showSearch="false"
            @select="value => onVChange(2)(value)"
        >
        </DropdownSingle>
      </div>
      <div class="flex-grow flex items-center gap-4">
        <label for="v3" class="flex-grow">
          Climate and Sustainability
          <span>[{{ priorities[2] }} points]</span>
        </label>
        <DropdownSingle
            :items="options"
            v-model="priorities[2]"
            :title="priorities[2]"
            :showSearch="false"
            @select="value => onVChange(3)(value)"
        ></DropdownSingle>
      </div>
      <div class="flex-grow flex items-center gap-4">
        <label for="v4" class="flex-grow">
          Segregation and Cohesion
          <span>[{{ priorities[3] }} points]</span>
        </label>
        <DropdownSingle
            :items="options"
            v-model="priorities[3]"
            :title="priorities[3]"
            :showSearch="false"
            @select="value => onVChange(4)(value)"
        ></DropdownSingle>
      </div>
      <div class="flex-grow flex items-center gap-4">
        <label for="v5" class="flex-grow">
          Public Health
          <span>[{{ priorities[4] }} points]</span>
        </label>
        <DropdownSingle
            :items="options"
            v-model="priorities[4]"
            :title="priorities[4]"
            :showSearch="false"
            @select="value => onVChange(5)(value)"
        ></DropdownSingle>
      </div>

      <div class="flex-grow flex items-center gap-4">
        <label for="v6" class="flex-grow">
          Crisis of Democratic Confidence
          <span>[{{ priorities[5] }} points]</span>
        </label>
        <DropdownSingle
            :items="options"
            v-model="priorities[5]"
            :title="priorities[5]"
            :showSearch="false"
            @select="value => onVChange(6)(value)"
        ></DropdownSingle>
      </div>

      <Button
          class="mt-6"
          :is-primary="true"
          :is-disabled="available > 0"
          @click="onSubmit"
      >
        Submit
      </Button>
    </div>
  </div>

</template>

<style scoped>

</style>