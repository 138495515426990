import {_PUT_STATUS, LIST_ASSETS} from '@/store/operations.js';
import {normalizeRedirect} from '@/lib/normalizeRedirect.js';

const handleExpiredAuthentication = (response) => {
    const {data}    = response ?? {}
    const {Error}   = data ?? {}
    const {code}    = Error ?? {}
    if (code === 401) {
        const searchParams = new URLSearchParams(window.location.search)
        searchParams.set("modal", "login")
        const redirectTo = searchParams.get('redirectTo')
        const isAuthRoute = window.location.hostname.includes('app')
        if (!redirectTo && !isAuthRoute) {
            searchParams.set("redirectTo", normalizeRedirect(window.location.protocol + '//' + window.location.hostname + window.location.pathname))
            window.location.search = searchParams.toString()
        }
        // redirect to login in redirect target app
        console.log(401, 'Authentication required, redirect to login')
        window.location.href = window.location.protocol + '//' + normalizeRedirect(redirectTo) + '?modal=login'
    }
}
export const handleResponse = (context, operation, resetState) => async response => {
    context.commit(LIST_ASSETS, response.data, resetState)
    handleExpiredAuthentication(response)
    context.commit(_PUT_STATUS, {event: operation, result: response.data})
    return response
}