<script>
import LSection from "@/components/layout/LSection.vue";
import {computed} from "vue";

export default {
    name: "LGamePage",
    components: {
        LSection
    },
    props: {
        noTitle: {
            default: false
        },
        isBlank: {
            default: false
        }
    }, setup(props) {
        const showContent = computed(() => {
            return !props.isBlank
        })
        return {
            showContent,
        }
    }

}
</script>

<template>
  <LSection
    :no-margin="true"
    class="h-full grow"
  >
    <div
      class="
      lGamePage
      layout
      fadeInAnimation
      text-wai-900 flex
      grow
      flex-col
    "
    >
      <div
        v-show="isBlank"
        class="
            flex
            grow flex-col items-center justify-center
          "
      >
        <slot name="blankContent" />
      </div>
      <div
        v-show="!isBlank"
        class=" my-6 flex h-full grow flex-col justify-between"
      >
        <div
          class="
                k1:gap-0
                !mx-auto flex max-w-3xl grow flex-col
                items-center
                justify-center
            "
        >
          <div
            v-if="!noTitle"
            class="k1:text-5xl !mx-auto text-center text-3xl font-bold
              "
          >
            <slot name="title" />
          </div>
          <div
            class="
                  k1:text-xl flex
                  flex-col items-center justify-start gap-1 pt-6 text-center text-base"
            style="margin-left: auto!important; margin-right: auto!important;"
          >
            <slot />
          </div>
          <div class="mt-7 flex grow justify-center space-x-8">
            <slot name="actions" />
          </div>
        </div>
      </div>
    </div>
  </LSection>
</template>
