<script>
import { computed } from 'vue'
import { useDocument } from '@/composables/document/useDocument.js'
import LAccordion from '@/components/layout/LAccordion.vue'

export default {
    name: 'FileReference',
    components: { LAccordion },
    props: {
        assistantId: {
            type: String,
            required: true,
        },
        id: {
            type: String,
            required: true,
        },
        filename: {
            type: String,
            required: true,
        },
        index: {
            type: String,
            default: '',
        },
    },
    setup(props) {
        const { chunk } = useDocument({
            alias: 'chunk',
            filter: {
                _appId: 'assistants',
                _dataType: 'Chunk',
                _id: props.id,
            },
            proxyId: props.assistantId,
            options: {
                autoLoad: true,
            },
        })
        const reference = computed(() => {
            let reference = props.filename
            if (chunk.value?.index) {
                reference += ` : ${chunk.value.index}`
            }
            return reference
        })

        const text = computed(() => {
            const isJSON = props.filename.toLowerCase().endsWith('.json')
            const isLoaded = chunk.value?.text
            if (!isLoaded)
                return `Loading reference ${props.filename}:${props.index}`
            if (isJSON) {
                const jsonString = `${chunk.value?.text}`
                return `\`\`\`json
${JSON.stringify(JSON.parse(jsonString), null, 2)}
\`\`\``
            }
            return (
                chunk.value?.text ??
                `Loading chunk ${props.filename}:${props.index}`
            )
        })

        const onShowChunk = () => {
            const url = new URL(window.location.href)

            const params = url.searchParams

            params.set('modal', 'chunk')
            params.set('_id', props.id)

            const newUrl = `${url.pathname}?${params.toString()}${url.hash}`

            window.history.pushState({}, '', newUrl)
        }

        return {
            text,
            reference,
            onShowChunk,
        }
    },
}
</script>

<template>
    <LAccordion class="bg-slate-900 overflow-scroll whitespace-break-spaces">
        <template #header>{{ reference }}</template>
        <template #default>
            <div class="w-max-[1000px]">
                <MarkdownIt :source="text"></MarkdownIt>
            </div>
        </template>
    </LAccordion>
</template>
