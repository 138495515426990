import {LAV_LINKET_KORT} from '@/store/operations.js';
import {throttleBufferedAction} from '@/store/lib/throttledBufferedAction.js';
import {getApiUrl} from '@/store/lib/apiUrls.js';
import axios from '@/plugins/axios.js';
import {handleResponse} from '@/store/actions/lib/handleResponse.js';

export default {
    [LAV_LINKET_KORT](context, {url}) {
        return throttleBufferedAction({
            action: axios.post,
            url: `${getApiUrl()}/kortlink`,
            params: {url}
        })
            .then(handleResponse(context, LAV_LINKET_KORT))
    }
}