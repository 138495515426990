import CHIAPIHome from "@/components/views/home/CHIAPIHome.vue";
import CHIAPILandingView from "@/components/views/CHIAPILandingView.vue";

export default [
    {
        path: "/api",
        name: 'api',
        component: CHIAPILandingView,
        children: [
            {
                path: '',
                name: 'apiHome',
                component: CHIAPIHome
            },
        ]
    },

]