<script>
import StepComponent from '../StepComponent.vue';

export default {
  name: "LInfoBox",
  components: {
    StepComponent
  }
}
</script>
<template>
  <div class="flex w-full gap-4 justify-center !mt-8 !mb-0 flex-wrap">
    <StepComponent v-if="$slots.first" stepNumber="1">
      <template #step1>
        <slot name="first"/>
      </template>
    </StepComponent>
    <StepComponent v-if="$slots.second" stepNumber="2">
      <template #step2>
        <slot name="second"/>
      </template>
    </StepComponent>
    <StepComponent v-if="$slots.third" stepNumber="3">
      <template #step3>
        <slot name="third"/>
      </template>
    </StepComponent>
    <StepComponent v-if="$slots.four" stepNumber="4">
      <template #step4>
        <slot name="four"/>
      </template>
    </StepComponent>
  </div>
</template>