<script>
import {state} from "@/store/store";
import {computed, onBeforeUnmount, ref} from "vue";
import LView from "@/components/layout/LView.vue";
import {useStore} from "vuex";
import {useRoute} from "vue-router";


export default {
  name: "FourOhFour",
  components: {
    LView,
  },
  props: {
    pathMatch: {
      type: String,
      default: "",
    },
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const appId = computed(() => store.state.appId)
    const clientId = computed(() => state.clientId)
    const showNotFound = ref(false)

    const timer = ref(setTimeout(() => {
      showNotFound.value = true
    }, 500))

    onBeforeUnmount(() => {
      clearTimeout(timer.value)
    })

    return {
      appId,
      clientId,
      route,
      showNotFound,
      state,
    };
  },
};
</script>
<template>
  <div class="404 view">
    <div v-show="showNotFound">
      <LView :use-margins="false">
        <template #menubar>

          <div class="p-4 rounded border bg-gray-200">
            Page not found for path {{ $route.path }} (appId: {{ appId }}, matched path: '{{ pathMatch }}')
          </div>

        </template>
        <template #default>
          <pre class="tracking-tight text-xs">{{ route }}</pre>
        </template>
      </LView>
    </div>
  </div>
</template>

<style scoped></style>
