<script>
import { onMounted, ref, watch } from "vue";

export default {
    name: "LAccordion",
    emits: ["expand", "collapse"],
    props: {
        isOpen: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, { emit }) {
        const isOpening = ref(props.isOpen);

        const onToggle = (event) => {
            isOpening.value = event.currentTarget.open;
            console.log("Accordion state:", isOpening.value);
            if (isOpening.value) {
                emit("expand");
            } else {
                emit("collapse");
            }
        };
        onMounted(() => {
            isOpening.value = props.isOpen;
        });

        // Watch for external changes to props.isOpen
        watch(
            () => props.isOpen,
            (newVal) => {
                isOpening.value = newVal;
            }
        );

        return {
            onToggle,
            isOpening,
        };
    },
};
</script>
<template>
    <div class="lAccordion w-full">
        <details class="group" :open="isOpening" @toggle="onToggle">
            <summary
                class="flex justify-between items-start gap-2 font-medium cursor-pointer list-none"
            >
                <slot name="header">
                    <div class="heading4 !mt-0 !mb-0">Show more...</div>
                </slot>
                <span class="border rounded-md hover:bg-white">
                    <svg
                        fill="none"
                        height="24"
                        shape-rendering="geometricPrecision"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1.5"
                        viewBox="0 0 24 24"
                        width="24"
                        class="duration-150 transition-all"
                        :class="isOpening ? 'transform rotate-180' : 'rotate-0'"
                    >
                        <path d="M6 9l6 6 6-6"></path>
                    </svg>
                </span>
            </summary>
            <div>
                <div class="edit group-open:animate-fadeIn">
                    <slot></slot>
                </div>
            </div>
        </details>
    </div>
</template>
