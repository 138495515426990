// todo: copy at /server/database/lib
export const permissions = { // group-node relationship permissions
    read: 0x1,
    operate: 0x2, // fx. access subscribed assets
    maintain: 0x10, // fx. see public data, create, edit
    review: 0x40, // fx. publish, retract
    develop: 0xF0, // fx. see all data create, delete
    manage: 0xF3, // fx. assign developers
    own: 0xF5, // fx. request to be forgotten
    manageApp: 0xF6, // fx. delete public owned docs
    devOp: 0xFF, // delete at any time
}
export const canRead = permission => permission & permissions.read
export const canOperate = permission => permission & permissions.operate
export const canMaintain = permission => permission & permissions.maintain
export const canReview = permission => permission & permissions.review
export const canDevelop = permission => permission & permissions.develop
export const canManage = permission => permission & permissions.manage
export const canOwn = permission => permission & permissions.own
export const canManageApp = permission => permission & permissions.manageApp
export const canDevOp = permission => permission & permissions.devOp

export const permissionName = threshold => Object.keys(permissions)
        .find(key => permissions[key] === threshold)
export const equivalentPermissions = permission => {
    if (!permissions.permission && !Object.values(permissions).includes(permission)) throw new Error(`Invalid permission: ${permission}`)
    return Object.values(permissions)
            .filter(p => p & permission)
}
