import config from "../../../config.cjs";
import { withPrefix } from "@/lib/prefixPostfix.js";

const { appPort, apiPath } = config;

export const getBaseUrl = () => {
    const { origin, host, hostname } = location;
    let backendHost = hostname;
    const inProduction = !(
        hostname.includes("localhost") || hostname.includes("127.0.0.1")
    );
    if (appPort && !inProduction) backendHost += ":" + appPort;
    // if (inProduction) backendHost += basePath // todo?: only for static files
    return origin.replace(host, backendHost);
};
export const getApiUrl = () => `${getBaseUrl()}${apiPath}`;

export const getUploadUrl = ({ path, postfix }) => {
    // /upload/:path/:postfix?
    let url = `${getApiUrl()}/upload${withPrefix(path, "/")}`;
    if (postfix) url += `${withPrefix(postfix, "/")}`;
    return url;
};

export const getAssistantUploadUrl = ({ _id, pipeName, listenerName }) => {
    // /upload/:appId/:postfix?
    if (!_id) throw "id is required";
    return `${getApiUrl()}/upload/assistants/Assistant/upload/files/${_id}/${pipeName}/${listenerName}`;
};

export const getAssistantsUploadUrl = ({ _appId }) => {
    // /upload/:appId/:postfix?
    if (!_appId) throw "appId is required";
    return `${getApiUrl()}/upload/${_appId}`;
};

export const getCreaVisionsUrl = (postfix) => {
    // /creavisions/:postfix
    postfix = postfix || "";
    if (postfix) postfix = withPrefix(postfix, "/");
    return `${getApiUrl()}/creavisions${postfix}`;
};
