<script>
import RecordsMenu from './GPT Explorer/RecordsMenu.vue'
import { useStore } from 'vuex'
import { onMounted } from 'vue'
import axios from 'axios'
import { getApiUrl } from '@/store/lib/apiUrls.js'
import { LIST_ASSETS } from '@/store/operations.js'

export default {
    name: 'APMMRecords',
    components: {
        RecordsMenu,
    },
    setup() {
        const store = useStore()
        store.state.docs.Record = store.state.docs?.Record || {}
        onMounted(() => {
            // load data
            axios
                .post(getApiUrl() + '/actions/showcase/Record/action/list', {
                    filter: {
                        type: 'AND',
                        filters: [
                            {
                                field: '_dataType',
                                operator: '=',
                                value: 'Record',
                            },
                        ],
                    },
                    pageSize: 20,
                    offset: 0,
                })
                .then((response) => {
                    const { data } = response
                    store.commit(LIST_ASSETS, data)
                })
        })
    },
}
</script>

<template>
    <div class="APMMRecords view">
        <div class="w-full pt-4 px-4">
            <RecordsMenu class="mb-10"></RecordsMenu>
            <router-view></router-view>
        </div>
    </div>
</template>
