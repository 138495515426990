import { reactive } from 'vue'
import questionsEn from './questions.js'
import questionsDk from './questionsDk.js'
import { shuffle } from '@mehimself/cctypehelpers'

let locale = 'en'
let attentionQuestionText = ''
let questions = {}
let categories = []

export const state = reactive({
    startGame: null,
    startRound: null,
    logPrompt: null,
    logResponse: null,
    endGame: null,
    gameStarted: false,
})
const applyLocale = (value) => {
    locale = value
    questions = locale === 'dk' ? questionsDk : questionsEn
    categories = Object.keys(questions)
    attentionQuestionText =
        locale === 'dk' ? 'Opmærksomhedsspørgsmål' : 'Attention Question'
}
export const onLocaleChange = (newLocale) => {
    locale = newLocale
    applyLocale(locale)
}
const resetState = () => {
    state.currentCategoryIndex = 0
    // applyLocale(locale)

    const currentCategory = categories[0]
    state.currentCategory = currentCategory
    state.currentCategoryQuestionCount = questions[currentCategory].length
    state.currentQuestionIndex = 0
    state.currentQuestion = questions[currentCategory][0]
    state.totalQuestions = Object.values(questions).reduce(
        (count, questions) => count + questions.length,
        0
    )
    state.totalNonAttention =
        state.totalQuestions -
        categories.filter((c) => c.startsWith(attentionQuestionText)).length
    state.evaluations = Object.fromEntries(
        categories.map((category) => [
            category,
            {
                correctAnswers: 0,
                totalAnswers: 0,
            },
        ])
    )
    state.isRunning = false
    state.score = 0
}
const scrambleAnswerOptions = () => {
    for (const category of Object.values(questions))
        for (const question of category) {
            shuffle(question.options)
        }
}

const iterateQuestions = () => {
    state.currentQuestionIndex++
    if (
        state.currentQuestionIndex >= state.currentCategoryQuestionCount ||
        state.currentCategory.startsWith(attentionQuestionText)
    ) {
        state.currentCategoryIndex++
        if (state.currentCategoryIndex === categories.length) {
            state.isRunning = false
            state.currentCategory = 'Feedback'
            state.endGame({
                reason: `completed`,
                status: 'completed',
                score:
                    Object.values(state.evaluations).reduce((a, b) => {
                        const value = b.correctAnswers ?? 0
                        return a + value
                    }, 0) / state.totalNonAttention,
                scoreLayout: JSON.stringify(state.evaluations),
            })
            return
        }
        state.currentCategory = categories[state.currentCategoryIndex]
        state.currentQuestionIndex = 0
        state.startRound({
            category: state.currentCategory,
        })
    }
    const nextQuestion =
        questions[state.currentCategory][state.currentQuestionIndex]
    state.currentQuestion = nextQuestion
    if (nextQuestion) {
        state.logPrompt({
            question: JSON.stringify(state.currentQuestion),
            category: state.currentCategory,
            questionIndex: state.currentQuestionIndex,
            correctAnswer: state.currentQuestion?.answer,
        })
    } else {
        // todo: ask attention question
    }
}

const scoreAnswer = (answer) => {
    const isCorrect = answer === state.currentQuestion.answer
    if (isCorrect) {
        state.evaluations[state.currentCategory].correctAnswers++
    }
    state.evaluations[state.currentCategory].totalAnswers++
    state.logResponse({
        isCorrect,
        answer,
    })
}

export const onAnswer = (answer) => {
    scoreAnswer(answer)
    iterateQuestions()
}

export const resetGame = () => {
    resetState()
    scrambleAnswerOptions()
    state
        .startGame({
            questions: JSON.stringify(questions),
        })
        .then((response) => {
            if (response?.data?.Game?.[0]?._id)
                state.startRound({
                    category: state.currentCategory,
                })
            return response
        })
        .then((response) => {
            state.logPrompt({
                question: JSON.stringify(state.currentQuestion),
                category: state.currentCategory,
                questionIndex: state.currentQuestionIndex,
                correctAnswer: state.currentQuestion.answer,
            })
            return response
        })
}
export const onPlayGame = () => {
    resetGame()
    state.isRunning = true
}
