<script>
import {computed} from "vue";

export default {
  name: "LSection",
  props: {
    noMargin: {
      type: Boolean,
      default: false,
    },
    maxWidth: {
      type: Number,
      default: 1440,
    }
  },
  setup(props) {
    const style = computed(() => {
      const style = {}
      if (!props.noMargin) {
        style.maxWidth = `${props.maxWidth}px`
      }
      return style
    })
    return {
      style
    }
  }
};
</script>
<template>
  <div
      class="
          lSection
          flex-grow
          w-full
          float-none
          flex flex-col
      "
  >
    <div
        class="
          relative
          flex-grow
          h-full w-full
          flex flex-col
        "
        :class="{'mx-auto': !noMargin}"
        :style="style"
    >
      <slot></slot>
    </div>
  </div>
</template>
