import { isObject } from '@mehimself/cctypehelpers'
import { computed, isRef, reactive, ref, toRefs, watch } from 'vue'
import { useStore } from 'vuex'
import { capitalize } from '@mehimself/cctypehelpers'
import { assertIsSupportedOperation } from '@/store/lib/veriffyOperation'
import { isJSIdentifier } from '@mehimself/cctypehelpers'
import { normalizeDocumentOperationKeys } from '@/composables/document/lib/normalizeDocumentOperationKeys'
import {
    DELETE_DOCUMENT,
    GET_DOCUMENT,
    PATCH_DOCUMENT,
    PUBLISH_DOCUMENT,
    RETRACT_DOCUMENT,
} from '@/store/operations/documentOperations'
import { areSame } from '@mehimself/cctypehelpers'
import { isFunction } from '@mehimself/cctypehelpers'

const debug = false

const verifyOperations = (operations) => {
    Object.values(operations).forEach(assertIsSupportedOperation)
}
const ingestOperations = (operations) => {
    operations = operations || {}
    if (!isObject(operations)) throw new Error('operations must be an object')

    normalizeDocumentOperationKeys(operations)

    let {
        getDocument,
        patchDocument,
        publishDocument,
        retractDocument,
        deleteDocument,
    } = operations

    if (!getDocument) operations.getDocument = GET_DOCUMENT
    if (!patchDocument) operations.patchDocument = PATCH_DOCUMENT
    if (!publishDocument) operations.publishDocument = PUBLISH_DOCUMENT
    if (!retractDocument) operations.retractDocument = RETRACT_DOCUMENT
    if (!deleteDocument) operations.deleteDocument = DELETE_DOCUMENT
    verifyOperations(operations)
    return operations
}
const defaultOptions = {
    loader: null, // function to call instead of loading
    autoLoad: false, // load from server if not found in store
    noAutoSync: false, // do not synchronize with server
    forceRefresh: false, // force load from server, even for immutable dataTypes
}
const ingestOptions = (options) => {
    options = options || {}
    const { decode } = options
    if (!isObject(options)) throw new Error('options must be an object')
    if (decode && !isFunction(decode))
        throw new Error('options.decode must be a function')
    Object.assign({}, defaultOptions, options)
    return options
}
const ingestFilter = (filter) => {
    if (!filter) throw new Error('filter is required')
    filter = isRef(filter) ? filter : ref(filter)
    const target = filter.value
    if (!isObject(target)) throw new Error('filter must be (ref of) an object')
    if (!target._dataType) throw new Error('filter._dataType is required')
    if (!target._id) throw new Error('filter._id is required')
    return filter
}
const ingestAlias = (alias) => {
    if (!alias) throw new Error('alias is required')
    if (!isJSIdentifier(alias))
        throw new Error('alias must be a valid JavaScript identifier')
    return alias
}
const determineIsImmutable = (filter) => {
    const dataType = filter?._dataType
    let isImmutable = true
    if (!dataType) return isImmutable
    if (dataType.startsWith('_')) return isImmutable
    return false
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const useDocument = ({
    alias, // _dataType may not yet be resolved
    operations,
    filter,
    proxyId,
    options,
}) => {
    if (!filter) throw new Error('filter is required')

    options = ingestOptions(options)
    filter = ingestFilter(filter)
    operations = ingestOperations(operations)
    alias = ingestAlias(alias)

    const Alias = capitalize(alias)
    const { loader, autoLoad, forceRefresh } = options

    const isImmutable = ref(alias.startsWith('_'))

    const store = useStore()
    const value = computed(() => {
        if (!filter.value) return null
        const { _dataType, _id } = filter.value ?? {}
        return store.state.docs[_dataType]?.[_id]
    })

    const context = reactive({
        [alias]: null,
        [`raw${Alias}`]: null,
        [`${alias}_isWaitingForFilter`]: true,
        [`${alias}_isLoading`]: false,
        [`${alias}_isLoaded`]: false,
        [`${alias}_isPristine`]: false,
        [`${alias}_error`]: null,
    })

    const loadDocument = async (filter, refresh = false) => {
        filter = ingestFilter(filter)
        context[`${alias}_isWaitingForFilter`] = false

        try {
            const { _appId, _dataType, _id } = filter.value
            if (!_appId || !_dataType || !_id) {
                console.warn(
                    4022,
                    alias,
                    filter.value,
                    'filter must have _appId, _dataType and _id. to load document.'
                )
            }
            const isLoaded = !!store.state.docs[_dataType]?.[_id]?._id
            const canLoad = !!(_appId && _dataType && _id)
            const shouldLoad = (canLoad && refresh) || (!isLoaded && autoLoad)
            if (shouldLoad) {
                context[`${alias}_isLoading`] = true
                const params = {
                    ...filter.value,
                }
                if (proxyId) params.proxyId = proxyId
                if (options.loader) {
                    await loader(params).finally(() => {
                        context[`${alias}_isLoading`] = false
                    })
                } else {
                    await store
                        .dispatch(operations.getDocument, params)
                        .finally(() => {
                            context[`${alias}_isLoading`] = false
                        })
                }
            }
        } catch (error) {
            console.log(4024, error, operations.getDocument, {
                ...filter?.value,
            })
            context[`${alias}_error`] = error
        }
    }

    watch(
        filter,
        (newVal, oldVal) => {
            isImmutable.value = determineIsImmutable(newVal) && !forceRefresh // expect immutable dataTypes to be loadable on forceRefresh
            const shouldLoad =
                !isImmutable.value &&
                autoLoad &&
                newVal._dataType &&
                newVal._dataType !== oldVal?._dataType &&
                newVal._id &&
                newVal._id !== oldVal?._id &&
                newVal._id !== 'new'

            if (shouldLoad) {
                context[`${alias}_isWaitingForFilter`] = false
                if (debug) console.log(2398, 'loading document', alias)
                if (loader)
                    loader(filter)
                        .catch((err) => {
                            console.log(4023, err)
                            context[`${alias}_error`] = err
                        })
                        .finally(() => {
                            context[`${alias}_isLoading`] = false
                        })
                else {
                    loadDocument(filter)
                        .catch((err) => {
                            console.log(4023, err)
                            context[`${alias}_error`] = err
                        })
                        .finally(() => {
                            context[`${alias}_isLoading`] = false
                        })
                }
            }
        },
        { immediate: true }
    )

    watch(
        // sync with store state changes
        value,
        (newValue) => {
            const isDocumentChange =
                newValue !== undefined &&
                !areSame(newValue, context[`raw${Alias}`])

            if (isDocumentChange) {
                context[`raw${Alias}`] = newValue
                if (debug)
                    console.log(2399, 'syncing  document', alias, newValue)

                let value = newValue

                if (options.decode) value = options.decode(value)

                context[alias] = value

                context[`${alias}_isPristine`] = true
                context[`${alias}_isLoaded`] = !!context[alias]
            }
        },
        { immediate: true }
    )

    const updateDocument = async (update) => {
        if (isImmutable.value) {
            console.warn(filter, update)
            throw new Error('Cannot update immutable  document')
        }
        context[`${alias}_isPristine`] = false
        try {
            context[`${alias}_isLoading`] = true
            await store.dispatch(operations.patchDocument, {
                ...filter,
                update,
            })
            context[`${alias}_isPristine`] = true
        } catch (error) {
            context[`${alias}_error`] = error
        } finally {
            context[`${alias}_isLoading`] = false
        }
    }
    const publishDocument = async () => {
        if (isImmutable.value) {
            throw new Error('Cannot publish immutable  document')
        }
        context[`${alias}_isPristine`] = false
        try {
            context[`${alias}_isLoading`] = true
            await store.dispatch(operations.publishDocument, filter.value)
            context[`${alias}_isPristine`] = true
        } catch (error) {
            context[`${alias}_error`] = error
        } finally {
            context[`${alias}_isLoading`] = false
        }
    }

    const retractDocument = async () => {
        if (isImmutable.value) {
            throw new Error('Cannot retract immutable  document')
        }
        context[`${alias}_isPristine`] = false
        try {
            context[`${alias}_isLoading`] = true
            await store.dispatch(operations.retractDocument, filter.value)
            context[`${alias}_isPristine`] = true
        } catch (error) {
            context[`${alias}_error`] = error
        } finally {
            context[`${alias}_isLoading`] = false
        }
    }
    const deleteDocument = async () => {
        if (isImmutable.value) {
            console.warn(filter)
            throw new Error('Cannot delete immutable document')
        }
        context[`${alias}_isPristine`] = false
        try {
            context[`${alias}_isLoading`] = true
            await store.dispatch(operations.deleteDocument, filter.value)
            context[`${alias}_isPristine`] = true
        } catch (error) {
            context[`${alias}_error`] = error
        } finally {
            context[`${alias}_isLoading`] = false
        }
    }

    return {
        ...toRefs(context),
        [`load${Alias}`]: loadDocument,
        [`update${Alias}`]: updateDocument,
        [`publish${Alias}`]: publishDocument,
        [`retract${Alias}`]: retractDocument,
        [`delete${Alias}`]: deleteDocument,
    }
}
