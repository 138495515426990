<script>
import TextInput from "@/components/forms/TextInput.vue";
import Button from "@/components/forms/Button.vue";
import LSection from "@/components/layout/LSection.vue";
import {computed, reactive, ref, watchPostEffect} from "vue";
import {useStore} from "vuex";
import StatusMessage from "@/components/status/StatusMessage.vue";
import {useRoute, useRouter} from "vue-router";
import {useStatusMessage} from '@/composables/useStatusMessage.js';
import {repopulateObject} from '@mehimself/cctypehelpers';
import {GET_SESSION} from "../../../../chi-ccf/operations.js";

export default {
  name: "EnterSessionTokenWithUserId",
  components: {
    Button,
    LSection,
    StatusMessage,
    TextInput,
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()

    const {setMessage, deleteMessage} = useStatusMessage(GET_SESSION)

    const login = ref({})

    const sessionKey = ref(route.query.sessionKey?.trim() || '')
    const hideKeyInputs = computed(() => !!sessionKey.value)
    login.value.key = sessionKey.value

    const userId = ref(route.query.userId?.trim())
    const userIdLabel = computed(() => route.query.userIdLabel?.trim() || 'User Id')
    login.value.userId = userId.value

    const isSent = ref(false)
    const inputError = reactive({
      key: login.value.key ? "" : null,
      userId: login.value.userId ? "" : null,
    })

    const isValid = computed(
        () =>
            !isSent.value &&
            Object.values(inputError)
                .every((msg) => msg === "")
    );

    watchPostEffect(() => {
      const {key, userId} = login.value;

      inputError.key =
          key !== null && !key
              ? "A valid session key is required"
              : "";
      inputError.userId =
          userId !== null && !key
              ? `A valid ${userIdLabel.value.toLowerCase()} is required`
              : "";
    });

    deleteMessage();

    const onKeyChange = (value) => {
      login.key = value.trim()
      isSent.value = false
    }
    const onUserIdChange = (value) => {
      login.userId = value.trim()
      isSent.value = false
    }

    const onSubmit = () => {
      repopulateObject(inputError, {})
      deleteMessage();
      const {key, userId} = login.value;

      inputError.key = !!key ? "" : "A valid key is required";
      inputError.userId = !!userId ? "" : `A valid ${userIdLabel.value} is required`;
      if (!isValid.value) return

      isSent.value = true;
      store
          .dispatch(GET_SESSION, {sessionKey: key, userId})
          .then(({data}) => {
            const {Error, User, _sessionKey} = data;
            isSent.value = false;
            if (Error) {
              setMessage({
                isError: true,
                body: Error.message,
              });
            }
            if (User) {
              store.state.sessionKey = _sessionKey
              router.push({query: {}})
            }
          })
          .finally(() => {
            isSent.value = false;
          })

      Object.keys(login.value).forEach((key) => delete login.value[key]);
    };
    const onCancel = () => router.push('/')

    return {
      isValid,
      login,
      inputError,
      hideKeyInputs,
      userIdLabel,

      onKeyChange,
      onUserIdChange,
      onCancel,
      onSubmit,
    };
  },
};
</script>

<template>
  <div class="enterSessionTokenWithUserId">
    <LSection :noMargin="true">
      <form @submit.prevent="onSubmit" ref="form" class="form">
        <h1 class="heading1 mb-4">
          Begin Session
          <hr class="gradientLine"/>
        </h1>
        <p>Please provide
          <span v-if="!hideKeyInputs">a session key and </span>
          your {{userIdLabel.toLowerCase()}} to get started.</p>
        <div
            v-if="!hideKeyInputs"
            class="flex flex-wrap"
        >
          <div class="w-full mb-6">
            <label for="email">Key</label>
            <TextInput
                :tabIndex="1"
                name="key"
                placeholder="Session Key"
                required
                :value="login.key"
                @change="onKeyChange"
                @input="onKeyChange"
            ></TextInput>
          </div>
        </div>
        <div class="flex flex-wrap">
          <div class="w-full mb-6">
            <label for="userId">{{ userIdLabel }}</label>
            <TextInput
                :tabIndex="1"
                name="userId"
                :placeholder="userIdLabel"
                required
                :value="login.userId"
                @change="onUserIdChange"
                @input="onUserIdChange"
            ></TextInput>
          </div>
        </div>
        <div class="flex flex-column gap-4">
          <StatusMessage operation="LOGIN"></StatusMessage>
          <div class="w-full flex justify-between">
            <Button
                class="button secondary"
                :tabindex="3"
                type="submit"
                @click="onCancel"
            >
              <template #right>Back</template>
            </Button>
            <Button
                :is-disabled="!isValid"
                :is-primary="isValid"
                @click="onSubmit"
            >
              Submit
            </Button>
          </div>
        </div>
      </form>
    </LSection>
  </div>
</template>

<style scoped></style>
