export default {
    Samfundsvidenskab: [
        {
            text: 'Hvilket af de følgende lande er en atomvåbenstat?',
            options: ['Kina', 'Norge', 'Canada', 'Sverige'],
            answer: 'Kina',
        },
        {
            text: 'Hvad er et andragende?',
            options: [
                'Skriftlig anmodning eller klage til en autoritet eller instans',
                'Indgivelse af en retssag til den kompetente domstol',
                'Kandidatur til politisk eller socialt embede',
                'Udtalelse om et juridisk spørgsmål',
            ],
            answer: 'Skriftlig anmodning eller klage til en autoritet eller instans',
        },
        {
            text: 'I hvilken økonomisk sektor bliver råmaterialer eller mellemprodukter omdannet til varer?',
            options: [
                'Den sekundære sektor',
                'Den primære sektor',
                'Den tertiære sektor',
                'Den kvartære sektor',
            ],
            answer: 'Den sekundære sektor',
        },
        {
            text: 'Hvad kaldes det, når en valuta bliver mindre og mindre værd?',
            options: ['Inflation', 'Deflation', 'Hyperflation', 'Hypoflation'],
            answer: 'Inflation',
        },
        {
            text: "Hvor mange stater er permanente medlemmer af FN's Sikkerhedsråd?",
            options: ['5', '10', '15', '20'],
            answer: '5',
        },
        {
            text: 'En folkeafstemning er et eksempel på',
            options: [
                'Direkte demokrati',
                'Repræsentativt demokrati',
                'Autokrati',
                'Parlamentarisk demokrati',
            ],
            answer: 'Direkte demokrati',
        },
        {
            text: 'Hvad er en atol?',
            options: [
                'Et ringformet koralrev',
                'En græsk guddom',
                'En ø med en aktiv vulkan',
                'En bjergsø',
            ],
            answer: 'Et ringformet koralrev',
        },
        {
            text: 'Hvad er rav lavet af?',
            options: [
                'Forstenet harpiks',
                'Vulkansk magma',
                'Silikater',
                'Krystaller',
            ],
            answer: 'Forstenet harpiks',
        },
        {
            text: 'Hvor levede Sokrates?',
            options: [
                'I Grækenland',
                'I Mesopotamien',
                'I Italien',
                'I det gamle Egypten',
            ],
            answer: 'I Grækenland',
        },
        {
            text: 'Cirka hvornår opstod det første liv på jorden?',
            options: [
                '~3.8 milliarder år siden',
                '~380 millioner år siden',
                '~38 millioner år siden',
                '~3.8 millioner år siden',
            ],
            answer: '~3.8 milliarder år siden',
        },
    ],
    'Opmærksomhedsspørgsmål 1': [
        {
            text: 'Hvad er det andet bogstav i alfabetet?',
            options: ['A', 'B', 'C', 'D'],
            answer: 'B',
        },
    ],
    Naturvidenskab: [
        {
            text: 'Cirka hvor stor er tyngdeaccelerationen (g)?',
            options: ['10 m/s²', '25 m/s²', '2 m/s²', '5 m/s²'],
            answer: '10 m/s²',
        },
        {
            text: 'Hvad kendetegner en diode?',
            options: [
                'Den tillader kun, at elektrisk strøm løber igennem i én retning',
                'Den lagrer elektriske ladninger',
                'Den forstærker elektriske signaler',
                'Den genererer et magnetfelt',
            ],
            answer: 'Den tillader kun, at elektrisk strøm løber igennem i én retning',
        },
        {
            text: 'Hvad er det mindste fælles multiplum af 6 og 4?',
            options: ['12', '24', '18', '6'],
            answer: '12',
        },
        {
            text: 'Hvad er summen af alle de indvendige vinkler i en firkant?',
            options: ['360°', '180°', '400°', '240°'],
            answer: '360°',
        },
        {
            text: 'Hvad er den kemiske formel for bordsalt?',
            options: ['NaCl', 'CaCl', 'NaS', 'S2'],
            answer: 'NaCl',
        },
        {
            text: 'Hvilken af disse er en ædelgas?',
            options: ['Neon', 'Oxygen', 'Hydrogen', 'Klor'],
            answer: 'Neon',
        },
        {
            text: 'Hvilket af disse dyr er et pattedyr?',
            options: ['Delfin', 'Hammerhaj', 'Ørn', 'Komodovaran'],
            answer: 'Delfin',
        },
        {
            text: 'Hvad er formålet med mitose?',
            options: [
                'Cellespredning under vækstprocesser',
                'Reproduktion',
                'Metabolisk regulering',
                'Kønscelledannelse',
            ],
            answer: 'Cellespredning under vækstprocesser',
        },
        {
            text: 'Hvad behandles med antibiotika?',
            options: [
                'Bakterielle infektioner',
                'Virale infektioner',
                'Forgiftninger',
                'Svampeinfektioner',
            ],
            answer: 'Bakterielle infektioner',
        },
        {
            text: 'Hvad er typiske symptomer på epilepsi?',
            options: [
                'Anfald og kortvarige bevidsthedstab',
                'Hukommelsesforstyrrelser og opmærksomhedsproblemer',
                'Langvarige ledsmerter',
                'Kvalme, opkast og mental forvirring',
            ],
            answer: 'Anfald og kortvarige bevidsthedstab',
        },
    ],
    'Opmærksomhedsspørgsmål 2': [
        {
            text: 'Hvilket tal er det mindste?',
            options: ['4', '7', '11', '13'],
            answer: '4',
        },
    ],
    Humaniora: [
        {
            text: 'Hvad er kerneværdierne i Konfutses lære?',
            options: [
                'Orden, harmoni og balance',
                'Retfærdighed, lighed og broderskab',
                'Selvsikkerhed, viljestyrke og mod',
                'Ærlighed, åbenhed og respekt',
            ],
            answer: 'Orden, harmoni og balance',
        },
        {
            text: 'Hvilken rækkefølge kendetegner tilegnelsen af viden gennem et eksperiment?',
            options: [
                'Teori - Hypotese - Udførelse - Observation - Evaluering',
                'Hypotese - Udførelse - Teori - Observation - Evaluering',
                'Teori - Evaluering - Hypotese - Udførelse - Observation',
                'Udførelse - Observation - Evaluering - Hypotese - Teori',
            ],
            answer: 'Teori - Hypotese - Udførelse - Observation - Evaluering',
        },
        {
            text: "Et berømt maleri af Dalí afbilder 'smeltende ure'. Hvilken kunstretning tilhører dette maleris stilart?",
            options: [
                'Surrealisme',
                'Impressionisme',
                'Naturalisme',
                'Romantik',
            ],
            answer: 'Surrealisme',
        },
        {
            text: "Hvad er det 'gyldne snit'?",
            options: [
                'Afstands- eller størrelsesforhold, som muliggør æstetiske proportioner',
                "Aristoteles' afhandling om kunstens natur",
                'En særlig type linjer i malerkunsten',
                'En graveringsteknik i renæssancen',
            ],
            answer: 'Afstands- eller størrelsesforhold, som muliggør æstetiske proportioner',
        },
        {
            text: 'Hvilken bog er skrevet af Fyodor Mikhailovich Dostoevsky?',
            options: [
                'Forbrydelse og straf',
                'Intriger og kærlighed',
                'Sex og kærlighed',
                'Drab og manddrab',
            ],
            answer: 'Forbrydelse og straf',
        },
        {
            text: "Hvad kæmper Don Quixote mod i Miguel de Cervantes' roman?",
            options: [
                'Vindmøller',
                'Briterne',
                'Et havuhyre',
                'Social uretfærdighed',
            ],
            answer: 'Vindmøller',
        },
        {
            text: 'Hvilket af disse er et træblæseinstrument?',
            options: ['Saxofon', 'Buglehorn', 'Tuba', 'Trompet'],
            answer: 'Saxofon',
        },
        {
            text: 'Hvilke musikere sidder i de forreste rækker (ved siden af dirigenten) i et symfoniorkester?',
            options: [
                'Strygerne',
                'Messingblæserne',
                'Percussionisterne',
                'Træblæserne',
            ],
            answer: 'Strygerne',
        },
        {
            text: 'Hvad er det mest anvendte byggemateriale i verden?',
            options: ['Beton', 'Træ', 'Stål', 'Mursten'],
            answer: 'Beton',
        },
        {
            text: 'Hvad er verdens højeste bygning?',
            options: [
                'Burj Khalifa i Dubai',
                'Shanghai Tower i Shanghai',
                'Makkah Royal Clock Tower i Mekka',
                'Ping An Finance 117 i Tianjin',
            ],
            answer: 'Burj Khalifa i Dubai',
        },
    ],
}
