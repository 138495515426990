import APMMRecords from "./components/views/APMM/APMMRecords.vue";
import GPTExplorer from "./components/views/APMM/GPTExplorer.vue";
import RecordsWelcome from "./components/views/APMM/RECORDS/RecordsWelcome.vue";
import componentLibraryHome from "./components/views/ComponentLibrary/ComponentLibraryHome.vue";

const hostProtocol = window.location.protocol
const hostDomain = window.location.host
    .split('.')
    .slice(1)
    .join('.')

export default [
    {
        path: '/library',
        name: 'library',
        meta: {
            requiresDevOp: true
        },
        component: () => import('./components/views/ComponentLibrary/layout/LComponentLibrary.vue'),
        children: [
            {
                path: '',
                name: 'componentLibraryHome',
                component: componentLibraryHome
            },
            {
                path: 'create',
                name: 'createLibraryComponent',
                component: () => import('./components/views/ComponentLibrary/CreateCHIComponent.vue')
            }
        ]
    },
    {
        path: '/APMM',
        name: 'apmm',
        component: () => import('./components/views/APMM/APMMHome.vue')
    },
    {
        path: '/APMM Assistant',
        name: 'apmmAssistant',
        beforeEnter: (to, from, next) => {
            window.location.href = to.meta.url;
        },
        meta: {
            url: `${hostProtocol}//assistants.${hostDomain}/assistant/asst_AoIC4OjVnBqLIirSv45aqEkC`
        }
    },
    {
        path: '/APMM/Records',
        name: 'records',
        component: APMMRecords,
        children: [
            {
                path: '',
                name: 'recordsWelcome',
                component: RecordsWelcome,
            },
            {
                path: 'guide',
                name: 'recordsGuide',
                component: () => import('./components/views/APMM/RECORDS/RecordsGuide.vue'),
            },
            {
                path: 'search',
                name: 'recordsSearch',
                component: () => import('./components/views/APMM/RECORDS/RecordsSearch.vue'),
            },
            {
                path: 'fileImport',
                name: 'recordsFileImport',
                component: () => import('./components/views/APMM/RECORDS/RecordsFileImport.vue'),
            },
            {
                path: 'entry/:boxNumber',
                name: 'recordsEntry',
                component: () => import('./components/views/APMM/RECORDS/entry/RecordsEntry.vue'),
                props: true
            }
        ]
    },
    {
        path: '/APMM/GPT Explorer',
        name: 'gptExplorer',
        component: GPTExplorer,
    }
]