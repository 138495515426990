<script>
import { computed } from "vue";

export default {
    name: "AlertError",
    // todo: markdown/html message
    props: {
        name: {
            type: String,
            default: "",
        },
        message: {
            required: true,
        },
        color: {
            type: String,
            default: `text-red-700 bg-red-100 dark:bg-red-200 dark:text-red-800`,
        },
    },
    setup(props) {
        const errorMessage = computed(
            () => props.message?.message ?? props.message ?? ""
        );
        const errorName = computed(
            () => props.message?.name ?? props.name ?? "Error"
        );
        return {
            errorMessage,
            errorName,
        };
    },
};
</script>
<template>
    <div class="alertError">
        {{ message }}
        <div
            class="p-4 rounded-lg flex items-center text-sm text-red-700 bg-red-100 dark:bg-red-200 dark:text-red-800"
            role="alert"
        >
            <div class="icon flex items-center">
                <slot name="icon"></slot>
            </div>

            <div class="flex flex-wrap px-4">
                <span v-if="name" class="font-bold pr-4">
                    {{ errorName }}
                </span>
                <div class="whitespace-pre-wrap">
                    {{ errorMessage }}
                </div>
            </div>
        </div>
    </div>
</template>
