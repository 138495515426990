<script>
import LGamePage from "../layout/LGamePage.vue";

export default {
    name: "Welcome",
    components: {
        LGamePage,
    },
}
</script>

<template>
  <LGamePage>
    <template #title>
      {{ $t('colorCatch.welcome') }}
      <h2 class="gamifiedh2">
        {{ $t('colorCatch.introHeader') }}
      </h2>
    </template>
    <template #default>
      <p v-html="$t('colorCatch.introText')" />
    </template>
    <template #actions>
      <button
        class="button text-xl "
        @click="$router.push({name:'color-catch-tutorial'})"
      >
        {{ $t('colorCatch.start') }}
      </button>
    </template>
  </LGamePage>
</template>

<style scoped>
</style>