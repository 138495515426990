import {
    FIND_CREA_VISIONS_SIMILAR_GALLERY_ENTRIES,
    GET_CREA_VISIONS_SESSION,
    LOG_CREA_VISIONS_ENTRY
} from './operations.js';
import axios from "@/plugins/axios";
import {getCreaVisionsUrl} from "@/store/lib/apiUrls";
import {handleResponse} from "@/store/actions/lib/handleResponse";
import {state} from "@/store/store";
import {getDataTypeActionUrl} from "@/store/actions/lib/getDataTypeActionUrl.js";

export default {
    [GET_CREA_VISIONS_SESSION](context, {eventName}) {
        return axios.post(
            getDataTypeActionUrl({
                    _appId: 'creavisions',
                    _dataType: 'CVSession',
                    endpoint: 'create',
                }),
                {
                    eventName
                }
        )
            .then(handleResponse(context, GET_CREA_VISIONS_SESSION))
    },
    [FIND_CREA_VISIONS_SIMILAR_GALLERY_ENTRIES](context, {query, amount}) {
        return axios.post(
            getCreaVisionsUrl('findSimilar'),
            {
                query,
                amount
            }
        )
            .then(handleResponse(context, FIND_CREA_VISIONS_SIMILAR_GALLERY_ENTRIES))
    },
    [LOG_CREA_VISIONS_ENTRY](context, entry) {
        return axios.post(
            getCreaVisionsUrl(`${state.eventName}/logEntry`),
            {
                ...entry
            }
        )
            .then(handleResponse(context, LOG_CREA_VISIONS_ENTRY))
    }
}