<script>
import { computed, ref, watch } from 'vue'
import Progressbar from '../Progressbar.vue'
import { state as game } from '../quizTime/lib/game.js'
import { useI18n } from 'vue-i18n'

export default {
    name: 'QuizTime',
    components: {
        Progressbar,
    },
    setup() {
        const { t } = useI18n()

        // const stepsWithIcons = [
        //     { label: 'Welcome', icon: 'QuestionMarkIcon' },
        //     { label: 'Social Science', icon: 'PlayIcon' },
        //     { label: 'Natural Science', icon: 'PlayIcon' },
        //     { label: 'Humanities', icon: 'PlayIcon' },
        //     { label: 'Feedback', icon: 'StarIcon' },
        // ]
        const stepsWithIcons = ref([
            { label: t('quizTime.steps.welcome'), icon: 'QuestionMarkIcon' },
            { label: t('quizTime.steps.socialScience'), icon: 'PlayIcon' },
            { label: t('quizTime.steps.naturalScience'), icon: 'PlayIcon' },
            { label: t('quizTime.steps.humanities'), icon: 'PlayIcon' },
            { label: t('quizTime.steps.feedback'), icon: 'StarIcon' },
        ]);


        const steps = stepsWithIcons.value.map((step) => step.label)

        const currentViewIndex = ref(0)
        const category = computed(() => game.currentCategory)

        const onProgress = (label) => {
            currentViewIndex.value = steps.indexOf(label)
        }

        watch(category, (category) => {
            if (steps.includes(category)) {
                onProgress(category)
            }
        })

        return {
            stepsWithIcons,
            currentViewIndex,
            onProgress,
        }
    },
}
</script>

<template>
  <div class="quizTime view flex !h-full w-full grow flex-col">
    <div
      class="flex size-full max-w-3xl grow flex-col self-center px-3 text-center"
    >
      <Progressbar
        :current="currentViewIndex"
        :steps-with-icons="stepsWithIcons"
      />
      <div class="flex h-full grow">
        <router-view @progress="onProgress" />
      </div>
    </div>
  </div>
</template>
