<script>
import StarIcon from "../icons/StarIcon.vue";
import QuestionMarkIcon from "../icons/QuestionMarkIcon.vue";
import PlayIcon from "../icons/PlayIcon.vue";
import TutorialIcon from "../icons/TutorialIcon.vue";
import {computed} from "vue";

export default {
  name: 'Progressbar',
  components: {
    StarIcon,
    QuestionMarkIcon,
    PlayIcon,
    TutorialIcon
  },
  props: {
    stepsWithIcons: {
      type: Array,
      required: true,
    },
    current: {
      type: Number,
      required: true
    },

  },
  setup(props) { // todo: refactor into layout component

    const steps = computed(() => {
      return props.stepsWithIcons.map(item => {
        const step = {
          label: item?.label ?? 'no label',
        }
        if (item.icon) step.icon = item.icon;
        return step;
      })
    });

    const reachedSteps = computed(() => {
      return props.stepsWithIcons.slice(0, props.current + 1).map(step => step.label);
    });


    const progress = computed(() => {
      if (props.stepsWithIcons.length <= 1) return 100; // Avoid division by zero
      return (props.current / (props.stepsWithIcons.length - 1)) * 100;
    });

    return {
      steps,
      reachedSteps,
      progress,
    }
  }
}
</script>
<template>
  <div class="h-20 flex items-center relative w-full mt-10 font-custom justify-between max-w-3xl ">
    <div class="border w-[94%] mx-4 bg-slate-200 h-2 rounded-md absolute ">
      <div
          class="w-full container-shadow h-2 bg-slate-700 transition-all duration-500 ease-in-out"
          :style="{
              width: progress && !isNaN(progress) ? progress + '%' : 0 + '%',
              backgroundColor: progress && !isNaN(progress) ? '#6a7fc1' : ''
          }"
      >
      </div>
    </div>
    <div
        class="relative group"
        v-for="(stepObj) in steps"
        :key="stepObj.label"
    >
      <div
          class="
              absolute -top-10
              text-sm
              before:absolute
              before:-z-10
              before:border-l-transparent
              before:border-r-transparent
              before:border-r-8
              before:border-l-8
              before:border-t-[8px]
              before:border-cognikitBlue
              before:top-[95%]
              before:left-1/2
              before:-translate-x-1/2
              text-white
              p-1 px-2
              rounded-lg
              bg-cognikitBlue
              left-1/2 translate-y-3 -translate-x-1/2
              whitespace-nowrap
              opacity-0
              duration-500
              transition-all
              group-hover:opacity-100
              group-hover:!translate-y-0
              -z-10
              text-shadow-small
          "
          :class="{ 'opacity-100  !translate-y-0': stepsWithIcons[current]?.label === stepObj.label }">
        {{ stepObj.label }}
      </div>
      <div
          :class="[
      'h-12 w-12 rounded-full transition-all duration-500 delay-100  ',
      { 'border-[#6a7fc1] bg-[#6a7fc1] shadow-md shadow-[#6a7fc1]/30 circle-shadow ': reachedSteps.includes(stepObj.label) },
      { '!bg-white border-4': !reachedSteps.includes(stepObj.label) }
    ]"
      >
        <Component
            :is="stepObj.icon ?? ''"
            class="z-20 grid h-full place-items-center transition-all duration-500"
            :class="{ '!opacity-30 bounce': !reachedSteps.includes(stepObj.label) }"
        />
      </div>
    </div>
  </div>
</template>

