const markRequestSentTime = config => {
    config.metadata = {sentAt: Date.now()}
    return config
}

export const configureRequests = axios =>
    axios.interceptors.request.use(
        config =>
            markRequestSentTime(config),
        error =>
            Promise.reject(error)
    )
