import { isObject } from '@mehimself/cctypehelpers'

/*
    SYNC WITH:
        FILE >>> ccneo4jdatabase/router/controllers/dataType/listDocuments/lib/validateDocumentFilter.js

    multi-attribute filter syntax:
    {
      type: "AND",
      filters: [
        {
          field: "age",
          operator: "greater_than",
          value: 30
        },
        {
          type: "OR",
          filters: [
            {
              field: "name",
              operator: "contains",
              value: "John"
            },
            {
              field: "skills",
              operator: "in",
              value: ["Python", "JavaScript"]
            }
          ]
        }
      ]
    }

    alternate simple filter syntax:
    {
      field: "age",
      operator: "greater_than",
      value: 30
    }
 */
const validateFilterExpression = (filter, path = '') => {
    if (!isObject(filter)) throw new Error(`${path} must be an object`)
    if (!filter.type) {
        if (!filter.field)
            throw new Error(`${path}.type OR filter.field is required`)
        if (!filter.value) throw new Error(`${path}.value is required`)
        filter.operator = filter.operator || 'equals'
        filter.operator = filter.operator.trim().toLowerCase().replace(' ', '_')
        return
    }

    if (!filter.filters) throw new Error(`${path}.filters is required`)
    if (!Array.isArray(filter.filters))
        throw new Error(`${path}.filters must be an array`)

    filter.filters.forEach((f, i) =>
        validateFilterExpression(f, path + `.filters[${i}]`)
    )
}
export const validateDocumentFilter = (filter) => {
    try {
        validateFilterExpression(filter)
    } catch (err) {
        throw new MalformedFilterError(filter, err.message)
    }
}

export class MalformedFilterError extends Error {
    constructor(filter, message) {
        const validFilterStructures = `
Document filter syntax:
FILTER: {
  DISJUNCTION | CLAUSE
}
CLAUSE: { // fx. document.age > 30
  field: "age",
  operator: "greater_than",
  value: 30
}
DISJUNCTION: {
    type: "OR|AND", // default is "AND"
    filters: [
        FILTER,
        ...
    ]
}
`
        super(
            [
                message,
                `Parsed filter: ${JSON.stringify(filter, null, 2)}`,
                validFilterStructures,
            ].join('\n')
        )
        this.name = 'MalformedDocumentFilterError'
        this.code = 400
    }
}
