<script>
import LGamePage from "../layout/LGamePage.vue";

export default {
    name: 'IntroScreen',
    components: {
        LGamePage,
    },
};
</script>
<template>
  <LGamePage>
    <template #title>
      {{ $t('mimeMix.welcome') }}
      <h2 class="gamifiedh2">
        {{ $t('mimeMix.introHeader') }}
      </h2>
    </template>
    <template #default>
      <span v-html="$t('mimeMix.introText')" />
    </template>
    <template #actions>
      <button
        class="button"
        @click="$router.push({name: 'mime-mix-intro-2'})"
      >
        {{ $t('shared.continue') }}
      </button>
    </template>
  </LGamePage>
</template>


<style scoped>
</style>
