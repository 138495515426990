<script>
import { computed } from "vue";

export default {
    name: "ProgressBar",
    props: {
        value: {
            type: Number,
            default: 0,
            validator: (value) => 0 <= value && value <= 100,
        },
    },
    setup(properties) {
        const valueStyle = computed(() => ({
            width: `${properties.value}%`,
        }));
        return {
            valueStyle,
        };
    },
};
</script>

<template>
  <div class="progressBar z-20 h-3 w-full rounded-lg bg-gray-200 dark:bg-gray-700">
    <div
      class="bg-assistant_primary-500 h-3 rounded-lg text-center"
      :style="valueStyle"
    />
  </div>
</template>

<style scoped></style>
