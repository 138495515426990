<script>

export default {
  name: "GPTExplorer",
  components: {
  },
}
</script>

<template>
<div class="GPTExplorer view">
  <div class="flex flex-col gap-2">
    <div>Upload zipped directory</div>
    <div>...</div>
  </div>
</div>
</template>

