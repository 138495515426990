
<script>
import {state} from "@/store/store";
import {computed} from "vue";
import MenuBar from "@/components/views/MenuBar.vue";
import LView from "@/components/layout/LView.vue";

export default {
  name: "CHIAPILandingView",
  components: {
    LView,
    MenuBar,
  },
  setup() {
    const clientId = computed(() => state.clientId);
    return {
      clientId,
      state,
    };
  },
};
</script>
<template>
  <div class="CHIAPI landing view">
    <LView :use-margins="false">
      <template #menubar>

        <MenuBar class="fixed w-full h-20 z-50"></MenuBar>

      </template>
      <template #default>

        <div class="mt-20 pt-20 h-full">
          <router-view></router-view>
        </div>

      </template>
    </LView>
  </div>
</template>

<style scoped></style>
