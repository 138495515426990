function enPluralizationRules(choice, choicesLength) {
    if (choice === 1) {
        return 0;
    }
    return choicesLength < 2 ? 0 : 1; // Plural
}

export default {
    en: enPluralizationRules
};
