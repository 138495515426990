<script>
import { onBeforeUnmount, ref, provide, computed, watch } from 'vue'
import Progressbar from '../Progressbar.vue'
import { onResetGame as setupSearchGame } from '../flashMatch/lib/search/game'
import { onResetGame as setupComparisonGame } from '../flashMatch/lib/comparison/game'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'

export default {
    name: 'FlashMatch',
    components: {
        Progressbar,
    },
    setup() {
        const currentViewIndex = ref(0)
        const route = useRoute()
        const highestStepIndex = ref(0)
        const { t } = useI18n()

        setupSearchGame()
        setupComparisonGame()

        const stepsWithIcons = [
            { label: 'Welcome', icon: 'QuestionMarkIcon' },
            { label: 'Search: Round 1', icon: 'PlayIcon' },
            { label: 'Search: Round 2', icon: 'PlayIcon' },
            { label: 'Comparison: Round 1', icon: 'PlayIcon' },
            { label: 'Comparison: Round 2', icon: 'PlayIcon' },
            { label: 'Feedback', icon: 'StarIcon' },
        ]

        const steps = stepsWithIcons.map((step) => step.label)

        //Workaround to avoid progressbar going back a step when user goes from game 1 to game 2
        watch(
            () => route.meta.stepLabel,
            (newLabel) => {
                let currentIndex = steps.indexOf(newLabel ?? 'Welcome')
                if (currentIndex > highestStepIndex.value) {
                    highestStepIndex.value = currentIndex
                }
            },
            { immediate: true }
        )

        const currentStep = computed(() => highestStepIndex.value)
        const onProgress = (label) =>
            (currentViewIndex.value = steps.indexOf(label))

        const leftArrowDown = ref(false)
        const rightArrowDown = ref(false)

        const handleKeydown = (event) => {
            if (event.key === 'ArrowLeft') {
                leftArrowDown.value = true
            } else if (event.key === 'ArrowRight') {
                rightArrowDown.value = true
            }
        }

        const resetKey = (event) => {
            if (event.key === 'ArrowLeft') {
                leftArrowDown.value = false
            } else if (event.key === 'ArrowRight') {
                rightArrowDown.value = false
            }
        }

        globalThis.addEventListener('keydown', handleKeydown)
        globalThis.addEventListener('keyup', resetKey)

        onBeforeUnmount(() => {
            globalThis.removeEventListener('keydown', handleKeydown)
            globalThis.removeEventListener('keyup', resetKey)
        })

        provide('leftArrowDown', leftArrowDown)
        provide('rightArrowDown', rightArrowDown)

        return {
            currentViewIndex,
            onProgress,
            currentStep,
            stepsWithIcons,
        }
    },
}
</script>

<template>
    <div class="flashMatch view flex h-full flex-col">
        <div class="fadeInAnimation flex w-full grow flex-col items-center">
            <Progressbar
                :current="currentStep"
                :steps-with-icons="stepsWithIcons"
            />
            <div class="flex grow flex-col">
                <router-view @progress="onProgress" />
            </div>
        </div>
    </div>
</template>

<style></style>
