<script>
import { useRouter } from 'vue-router';
import { computed } from 'vue';
import LGamePage from '../layout/LGamePage.vue';
import LSection from '@/components/layout/LSection.vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';

export default {
    name: 'Welcome',
    components: {
        LSection,
        LGamePage,
    },
    setup() {
        const store = useStore();
        const router = useRouter();
        const suiteId = computed(() => store.state.delphi._suiteId);

        const canPlay = computed(() => !!suiteId.value);
        const onStart = () => {
            if (!canPlay.value) return;
            router.push({ name: 'quiz-time-game' });
        };
        return {
            canPlay,
            onStart,
        };
    },
};
</script>

<template>
  <LSection>
    <LGamePage>
      <template #title>
        {{ $t('quizTime.welcome') }}
        <h2 class="gamifiedh2">
          {{ $t('quizTime.intro') }}
        </h2>
      </template>
      <template #default>
        <p class="text-md leading-relaxed">
          {{ $t('quizTime.instructions.description') }}
          <br>
          {{ $t('quizTime.instructions.task') }}
          <br><br>
          {{ $t('quizTime.instructions.encouragement') }}
        </p>
      </template>
      <template #actions>
        <button
          v-if="canPlay"
          class="button text-xl"
          @click="onStart"
        >
          {{ $t('quizTime.actions.start') }}
        </button>
        <div v-else>
          {{ $t('quizTime.actions.error') }}
        </div>
      </template>
    </LGamePage>
  </LSection>
</template>
