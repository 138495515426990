<script>
import { onMounted, ref } from 'vue'

export default {
    name: 'Space',
    setup() {
        return {}
    },
}
</script>

<template>
    <div class="space">
        https://codepen.io/NazarAzhar/pen/zqXMqP
        <div class="stars"></div>
        <div class="twinkling"></div>
        <div class="clouds"></div>
    </div>
</template>

<style scoped>
.stars,
.twinkling,
.clouds {
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
}

.stars {
    z-index: 0;
    background: #000 url('https://i.imgur.com/YKY28eT.png') repeat top center;
}

.twinkling {
    z-index: 1;
    background: transparent url('https://i.imgur.com/XYMF4ca.png') repeat top
        center;
    animation: move-twink-back 200s linear infinite;
}

.clouds {
    z-index: 2;
    background: transparent url('https://i.imgur.com/mHbScrQ.png') repeat top
        center;
    animation: move-clouds-back 200s linear infinite;
}

@keyframes move-twink-back {
    from {
        background-position: 0 0;
    }
    to {
        background-position: -10000px 5000px;
    }
}

@keyframes move-clouds-back {
    from {
        background-position: 0 0;
    }
    to {
        background-position: 10000px 0;
    }
}
</style>
