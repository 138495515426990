<script>
import LInfoBox from './layout/LInfoBox.vue'
import LGamePage from '../layout/LGamePage.vue'

export default {
    name: 'Welcome',
    components: { LGamePage, LInfoBox },
}
</script>

<template>
    <LGamePage>
        <template #title>
            {{ $t('morningMemo.title') }}
            <h2 class="gamifiedh2">
                {{ $t('morningMemo.subtitle') }}
            </h2>
        </template>
        <template #default>
            <span v-html="$t('morningMemo.text')" />
        </template>

        <template #actions>
            <button
                class="button"
                @click="$router.push({ name: 'morning-memo-test' })"
            >
                {{ $t('shared.continue') }}
            </button>
        </template>
    </LGamePage>
</template>
