export default [
    {
        blockId: 'VS_1',
        stimuli: [
            [6, 1, 5, 4, 1, 5],
            [3, 7, 3, 3, 7, 3],
            [5, 6, 1, 5, 6, 1],
            [7, 0, 8, 7, 0, 8],
            [2, 3, 7, 2, 3, 7],
            [4, 8, 9, 4, 8, 8],
            [1, 5, 4, 2, 5, 4],
            [9, 4, 5, 9, 4, 5],
            [8, 9, 9, 8, 9, 9],
            [3, 2, 3, 3, 2, 3],
            [4, 8, 2, 4, 8, 5],
            [7, 1, 6, 7, 6, 6],
            [5, 9, 1, 9, 9, 1],
            [6, 4, 8, 6, 4, 8],
            [8, 6, 0, 8, 2, 0],
            [1, 0, 7, 1, 2, 7],
            [9, 5, 1, 9, 5, 1],
            [2, 2, 3, 2, 2, 9],
            [3, 7, 5, 3, 7, 5],
            [7, 3, 0, 7, 3, 0],
            [4, 7, 7, 4, 7, 7],
            [5, 3, 8, 5, 3, 8],
            [8, 8, 4, 8, 8, 1],
            [6, 9, 2, 9, 9, 2],
            [1, 0, 9, 1, 0, 9],
            [2, 5, 6, 2, 5, 6],
            [9, 6, 4, 3, 6, 4],
            [9, 4, 9, 9, 4, 9],
            [1, 2, 0, 1, 2, 0],
            [3, 1, 2, 3, 1, 2],
            [7, 4, 8, 7, 4, 2],
            [4, 3, 7, 4, 3, 7],
            [6, 6, 3, 6, 6, 8],
            [2, 8, 6, 2, 1, 6],
            [8, 2, 5, 8, 3, 5],
            [5, 9, 1, 5, 9, 1],
            [2, 7, 2, 2, 7, 2],
            [3, 1, 9, 3, 1, 9],
            [6, 5, 6, 6, 7, 6],
            [1, 0, 4, 5, 0, 4],
            [7, 1, 1, 4, 1, 1],
            [5, 7, 0, 5, 7, 8],
        ]
    },
    {
        blockId: 'VS_2',
        stimuli: [
            [2, 3, 8, 2, 3, 8],
            [7, 8, 5, 7, 6, 5],
            [4, 4, 9, 4, 4, 2],
            [5, 1, 2, 5, 1, 7],
            [6, 2, 5, 6, 2, 5],
            [1, 5, 0, 1, 5, 0],
            [9, 9, 1, 9, 9, 1],
            [8, 0, 8, 8, 0, 8],
            [2, 5, 4, 2, 5, 4],
            [3, 8, 3, 3, 8, 9],
            [1, 6, 6, 1, 6, 6],
            [9, 3, 9, 9, 3, 9],
            [8, 7, 2, 8, 1, 2],
            [3, 0, 7, 3, 0, 5],
            [5, 1, 0, 5, 1, 0],
            [6, 2, 6, 6, 2, 6],
            [4, 9, 9, 4, 9, 3],
            [2, 4, 3, 2, 4, 3],
            [7, 1, 7, 4, 1, 7],
            [1, 8, 8, 3, 8, 8],
            [5, 6, 5, 5, 9, 5],
            [9, 9, 1, 9, 9, 1],
            [2, 4, 4, 2, 6, 4],
            [6, 7, 2, 6, 7, 4],
            [8, 3, 3, 8, 2, 3],
            [4, 2, 7, 4, 2, 7],
            [7, 5, 4, 6, 5, 4],
            [3, 0, 9, 3, 0, 1],
            [3, 0, 2, 3, 3, 2],
            [1, 5, 5, 3, 5, 5],
            [6, 9, 8, 6, 9, 8],
            [4, 4, 1, 4, 4, 1],
            [2, 8, 6, 2, 8, 1],
            [5, 7, 0, 5, 7, 0],
            [9, 3, 1, 9, 3, 1],
            [8, 6, 0, 1, 6, 0],
            [7, 2, 5, 7, 2, 5],
            [4, 1, 3, 4, 1, 4],
            [7, 2, 7, 5, 2, 7],
            [1, 1, 9, 1, 1, 9],
            [5, 5, 4, 5, 5, 4],
            [2, 0, 6, 2, 0, 6],
        ]
    }
]