
<script>
export default {
  name: "CloseThickIcon",
  props: {
    title: {
      type: String,
      default: "CloseThick icon"
    },
    decorative: {
      type: Boolean,
      default: false
    },
    fillColor: {
      type: String,
      default: "currentColor"
    },
    size: {
      type: Number,
      default: 20
    }
  }
}
</script>
<template>
  <span :aria-hidden="decorative"
        v-bind="$attrs"
        :aria-label="title"
        class="material-design-icon close-thick-icon"
        role="img"
  >
    <svg
        xmlns="http://www.w3.org/2000/svg"
        :fill="fillColor"
        class="material-design-icon__svg"
        :width="size"
        :height="size"
        viewBox="0 0 24 24">
      <path d="M20 6.91L17.09 4L12 9.09L6.91 4L4 6.91L9.09 12L4 17.09L6.91 20L12 14.91L17.09 20L20 17.09L14.91 12L20 6.91Z">
        <title>{{ title }}</title>
      </path>
    </svg>
  </span>
</template>
