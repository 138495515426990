import {useInBrowser} from '@/lib/useInBrowser.js';
import {computed, onMounted, ref} from "vue";

export const useWindowDimensions = () => {
    const {isInBrowser} = useInBrowser()
    const getWindowWidth = () => {
        if (!isInBrowser) return 1
        return window?.innerWidth
    }
    const getWindowHeight = () => {
        if (!isInBrowser) return 1
        return window?.innerHeight
    }
    const setWindowWith = () => windowWidth.value = getWindowWidth()
    const setWindowHeight = () => windowHeight.value = getWindowHeight()
    const setWindowDimensions = () => {
        setWindowWith()
        setWindowHeight()
    }
    const windowWidth = ref(null)
    const windowHeight = ref(null)
    const aspectType = computed(() => {
        let aspect = ''
        if (windowHeight.value) {
            if (windowHeight.value > windowWidth.value) aspect = 'portrait'
            else if (windowHeight.value == windowWidth.value) aspect = 'square'
            else if (windowHeight.value < windowWidth.value) aspect = 'landscape'
        }
        return aspect
    })
    if (isInBrowser) {
        onMounted(() => {
            window?.addEventListener('resize', setWindowDimensions)
            setWindowDimensions()
        })
        setWindowDimensions()
    }
    return {
        aspectType,
        getWindowHeight,
        getWindowWidth,
        windowHeight,
        windowWidth,
    }
}