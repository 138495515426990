import {
    END_GAME,
    END_GAME_ROUND,
    GENERATE_DATA_FILES,
    INIT_LOCAL_SUITE,
    INIT_STUDY_SUITE,
    LIST_DATA_FILES,
    LOG_USER_PROMPT,
    LOG_USER_RESPONSE,
    REQUEST_GLOVE_DISTANCE,
    START_GAME,
    START_GAME_ROUND,
} from './operations.js'
import { throttleBufferedAction } from '@/store/lib/throttledBufferedAction'
import { handleResponse } from '@/store/actions/lib/handleResponse'
import axios from '@/plugins/axios'
import { getDataTypeActionUrl } from '@/store/actions/lib/getDataTypeActionUrl'
import { getDocumentActionUrl } from '@/store/actions/lib/getDocumentActionUrl'
import { v4 as uuid } from 'uuid'
import { getAppActionUrl } from '@/store/actions/lib/getAppActionUrl.js'

const _appId = 'delphi'
export default {
    [INIT_STUDY_SUITE](
        context,
        { _participantId, parentOrigin, resetParticipantForTesting }
    ) {
        // do not touch before 2050
        const url = getDataTypeActionUrl({
            _appId,
            _dataType: 'Suite',
            endpoint: 'initStudySuite',
        })
        return throttleBufferedAction({
            action: axios.post,
            url,
            params: {
                parentOrigin,
                _participantId,
                resetParticipantForTesting,
            },
        })
            .then((response) => {
                context.state.delphi = context.state.delphi || {}
                context.state.delphi._participantId = _participantId
                context.state.delphi._suiteId = response.data?.Suite[0]?._id
                context.state.delphi._resumableGames =
                    response.data?._resumableGames
                return response
            })
            .then(handleResponse(context, INIT_STUDY_SUITE))
    },
    [INIT_LOCAL_SUITE](context, { _participantId } = {}) {
        const url = getDataTypeActionUrl({
            _appId,
            _dataType: 'Suite',
            endpoint: 'initLocalSuite',
        })
        return throttleBufferedAction({
            action: axios.post,
            url: url,
            params: {
                _participantId,
            },
        })
            .then((response) => {
                context.state.delphi = context.state.delphi || {}
                context.state.delphi._participantId = _participantId
                context.state.delphi._suiteId = response.data?.Suite[0]?._id
                context.state.delphi._resumableGames =
                    response.data?._resumableGames
                return response
            })
            .then(handleResponse(context, INIT_LOCAL_SUITE))
    },
    [START_GAME](context, { gameName, attributes = {} }) {
        const url = getDataTypeActionUrl({
            _appId,
            _dataType: 'Game',
            endpoint: 'startGame',
        })
        return throttleBufferedAction({
            action: axios.post,
            url: url,
            params: {
                _participantId: context.state.delphi._participantId,
                _suiteId: context.state.delphi._suiteId,
                gameName,
                timestamp: Date.now(),
                attributes,
            },
        }).then((response) => {
            // remove from resumables and make game locally non-resumable
            context.state.delphi._resumableGames?.splice(
                context.state.delphi._resumableGames.indexOf(gameName),
                1
            )
            context.state.delphi.__currentGameId = response.data?.Game[0]?._id
            return handleResponse(context, START_GAME)(response)
        })
    },
    [START_GAME_ROUND](context, { attributes = {} }) {
        context.state.delphi.__currentRoundId = uuid()

        const url = getDataTypeActionUrl({
            _appId,
            _dataType: 'Round',
            endpoint: 'startRound',
        })
        return throttleBufferedAction({
            action: axios.post,
            url: url,
            params: {
                _participantId: context.state.delphi._participantId,
                _gameId: context.state.delphi.__currentGameId,
                _roundId: context.state.delphi.__currentRoundId,
                timestamp: Date.now(),
                attributes,
            },
        }).then(handleResponse(context, START_GAME_ROUND))
    },
    [END_GAME_ROUND](context, { attributes = {} }) {
        const url = getDataTypeActionUrl({
            _appId,
            _dataType: 'Round',
            endpoint: 'endRound',
        })
        return throttleBufferedAction({
            action: axios.post,
            url: url,
            params: {
                ...attributes,
                _participantId: context.state.delphi._participantId,
                _gameId: context.state.delphi.__currentGameId,
                timestamp: Date.now(),
                attributes,
            },
        }).then(handleResponse(context, END_GAME_ROUND))
    },
    [LOG_USER_PROMPT](context, { attributes = {} }) {
        context.state.delphi.__currentPromptId = uuid()
        const url = getDataTypeActionUrl({
            _appId: 'delphi',
            _dataType: 'Prompt',
            endpoint: 'logPrompt',
        })
        attributes = {
            _participantId: context.state.delphi._participantId,
            _gameId: context.state.delphi.__currentGameId,
            _roundId: context.state.delphi.__currentRoundId,
            _promptId: context.state.delphi.__currentPromptId,
            timestamp: Date.now(),
            attributes,
        }
        return axios
            .post(url, attributes)
            .then(handleResponse(context, LOG_USER_PROMPT))
    },
    [LOG_USER_RESPONSE](context, { attributes = {} }) {
        const url = getDataTypeActionUrl({
            _appId,
            _dataType: 'Response',
            endpoint: 'logResponse',
        })
        attributes = {
            _participantId: context.state.delphi._participantId,
            _gameId: context.state.delphi.__currentGameId,
            _roundId: context.state.delphi.__currentRoundId,
            _promptId: context.state.delphi.__currentPromptId,
            timestamp: Date.now(),
            attributes,
        }
        return axios
            .post(url, attributes)
            .then(handleResponse(context, LOG_USER_RESPONSE))
    },
    [END_GAME](context, { attributes = {} }) {
        const url = getDocumentActionUrl({
            _appId,
            _dataType: 'Game',
            _id: context.state.delphi.__currentGameId,
            endpoint: 'endGame',
        })
        return throttleBufferedAction({
            action: axios.post,
            url: url,
            params: {
                attributes,
                _participantId: context.state.delphi._participantId,
                timestamp: Date.now(),
            },
        }).then((response) => {
            delete context.state.delphi.__currentGameId
            return handleResponse(context, END_GAME)(response)
        })
    },
    [LIST_DATA_FILES](context) {
        const url = getDataTypeActionUrl({
            _appId,
            _dataType: 'File',
            endpoint: 'list',
        })
        return throttleBufferedAction({
            action: axios.post,
            url,
        }).then(handleResponse(context, LIST_DATA_FILES))
    },
    [GENERATE_DATA_FILES](context) {
        const url = getDataTypeActionUrl({
            _appId,
            _dataType: 'File',
            endpoint: 'generate',
        })
        return throttleBufferedAction({
            action: axios.post,
            url,
        }).then(handleResponse(context, GENERATE_DATA_FILES))
    },
    [REQUEST_GLOVE_DISTANCE](context, { word1, word2 }) {
        const url = getAppActionUrl({
            _appId,
            endpoint: 'gloveSemanticDistance',
        })
        return throttleBufferedAction({
            action: axios.post,
            url: url,
            params: {
                word1: word1,
                word2: word2,
            },
        }).then(handleResponse(context, REQUEST_GLOVE_DISTANCE))
    },
}
