<script>
import Progressbar from "../Progressbar.vue";
import {computed} from "vue";
import {useRoute} from "vue-router";
import LSection from "@/components/layout/LSection.vue";

export default {
    name: "MorningMemo",
    components: {
        LSection,
        Progressbar,
    },
    setup() {
        const route = useRoute();

        const stepsWithIcons = [
            {label: 'Welcome', icon: 'QuestionMarkIcon'},
            {label: 'Test', icon: 'TutorialIcon'},
            {label: 'Background Noise', icon: 'TutorialIcon'},
            {label: 'Talk', icon: 'PlayIcon'},
            {label: 'How Do You Feel', icon: 'PlayIcon'},
            {label: 'Feedback', icon: 'StarIcon'},
        ];

        const steps = stepsWithIcons.map(step => step.label);


        const currentStepIndex = computed(() => steps.indexOf(route.meta.stepLabel)) // defined in routes.js

        return {
            currentStepIndex,
            stepsWithIcons,
        };
    },
};
</script>

<template>
  <LSection>
    <div class=" sticky top-0 flex w-full justify-center">
      <Progressbar
        :current="currentStepIndex"
        :steps-with-icons="stepsWithIcons"
      />
    </div>
    <router-view />
  </LSection>
</template>
