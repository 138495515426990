import kortlinkActions from './kortlinkActions.js'
import assistantActions from '../../../chi-assistants/actions/actions.js'
import delphiActions from '../../../chi-delphi/actions.js'
import creaVisionsActions from '../../../chi-creavisions/actions.js'
import appActions from '../../../chi-app/actions/actions.js'
import uploadActions from './uploadActions.js'
import documentActions from './documentActions.js'

const actions = {}

const addAction = (name, action) => {
    if (actions[name]) throw new Error(`action ${name} already exists`)
    actions[name] = action
}

for (const name in creaVisionsActions) { addAction(name, creaVisionsActions[name]) }
for (const name in kortlinkActions) { addAction(name, kortlinkActions[name]) }
for (const name in delphiActions) { addAction(name, delphiActions[name]) }
for (const name in assistantActions) { addAction(name, assistantActions[name]) }
for (const name in appActions) { addAction(name, appActions[name]) }
for (const name in uploadActions) { addAction(name, uploadActions[name]) }
for (const name in documentActions) { addAction(name, documentActions[name]) }

export default actions