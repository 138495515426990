<script>
import {computed, ref, watch} from "vue";
import {getGravatarUrl} from "@/components/gravatar/lib/getGravatarUrl";
import {defaultImageCategories} from "@/components/gravatar/lib/defaultImageCategories";

const maxGravatarSize = 2048
const isUrl = value => /^https?:\/\//.test(value)
export default {
  name: "Gravatar",
  emits: ['error'],
  props: {
    email: {
      type: String,
      required: true,
      validator: v => /^[^@]+@[^@]+\.[^@]+$/.test(v)
    },
    size: {
      type: Number,
      default: 80,
      validator: value => 6 <= value && value <= maxGravatarSize
    },
    defaultImageSrc: {
      type: String,
      default: 'robohash',
      validator: value => defaultImageCategories.includes(value) || isUrl(value) || value[0] === '/'
    },
  },
  setup(props, {emit}) {
    const error = ref(null)
    const src = computed(() => {
      let url = getGravatarUrl(
          props.email, {
            size: 2 * props.size,
            defaultImageSrc: props.defaultImageSrc
          }
      )
      return url
    })

    watch(src, value => {
      error.value = null
      if (!value) error.value = {message: "Illegal gravatar url"}
    }, {immediate: true})

    const style = computed(() => {
      const style = {}
      if (props.size) style.maxHeight = props.size + 'px'
      return style
    })

    const onError = () => emit('error', error.value)

    return {
      error,
      onError,
      src,
      style,
    }
  }
}
</script>

<template>
  <div class="gravatar">{{ error }}
    <img
        class="gravatar"
        :src="src"
        :style="style"
        @error="onError"
    >
  </div>
</template>


<style scoped>

</style>
